import { Box, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";

const Home = () => {
  const { authState } = useAuth();

  const subsubscribe = async (registration: any) => {
    const PUBLIC_VAPID_KEY =
      "BG8ABJsLlc75kvNY0fU2vjPMaIdv6DaIUFe1dUBVasag_3ddv0OyhDIOFyI5NxpBgLTlUAYfPuJFtIASnktNOmE";

    const Subcrito = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: PUBLIC_VAPID_KEY,
    });
    await fetch(
      `https://visitik.api-bartik.com:4005/api/v1/notification/subsubscribe_notification/${authState.user?.id}`,
      {
        method: "POST",
        body: JSON.stringify(Subcrito),
        headers: {
          "content-Type": "application/json",
        },
      }
    );
  };

  function initialiseUI(registration: any) {
    registration.pushManager
      .getSubscription()
      .then(async function (subscription: any) {
        const isSubscribed = !(subscription === null);

        if (isSubscribed) {
        } else {
          subsubscribe(registration);
        }
      });
  }

  const subsubscribeNotification = async () => {
    navigator.serviceWorker.ready.then(async function (registration) {
      initialiseUI(registration);
      if (!registration) {
        console.log("error");
      }
    });
  };

  useEffect(() => {
    subsubscribeNotification();
  }, []);

  return (
    <Box>
      <Text>En ejecucion</Text>
    </Box>
  );
};

export default Home;
