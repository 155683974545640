import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Grid,
  GridItem,
  Spinner,
  Text,
  Heading,
  Switch,
  useColorMode,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import es from "dayjs/locale/es";
import { FiInfo } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";
import fallbackImage from "../assets/images/no-avatar.png";

interface Props {
  onToggle: () => void;
  notifications: any;
  isLoading: any;
  onClose: () => void;
  updateCount: (id: any) => void;
}

const NotificationCard = ({
  notifications,
  isLoading,
  onClose,
  updateCount,
}: Props) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgBoxOutlet = useColorModeValue("gray.50", "whiteAlpha.50");
  const secondaryTextColor = useColorModeValue("S", "gray.400");
  const titleColor = useColorModeValue("gray.600", "gray.100");

  return (
    <>
      {isLoading && (
        <>
          <Spinner mr="0.5em" /> Cargando
        </>
      )}
      {notifications.length !== 0 &&
        notifications.map((data: any) => (
          <Box width="full" key={data.id}>
            <RouterLink
              to={`/app/request-details/${data.request_id}`}
              onClick={() => updateCount(data.id)}
            >
              <Grid
                templateColumns="repeat(5, 1fr)"
                gap={2}
                border="1px"
                alignContent="center"
                alignItems="center"
                alignSelf="center"
                textAlign="center"
                borderColor="gray.200"
                borderRadius={"md"}
                p={1}
                mb={2}
              >
                <GridItem rowSpan={1} colSpan={1}>
                  <Avatar
                    marginTop="5px"
                    marginBottom="5px"
                    src={
                      data.imageUrl
                        ? `${process.env.REACT_APP_IMAGES_API_URL}/image/get-image-profile/${data.imageUrl}`
                        : fallbackImage
                    }
                  >
                    <AvatarBadge boxSize="1.1em" />
                  </Avatar>
                </GridItem>

                <GridItem colSpan={4}>
                  <Text fontWeight="bold" fontSize="sm" color={titleColor}>
                    {data.titleNotfication}

                    {data.readed === 1 ? (
                      <Badge colorScheme="green">New</Badge>
                    ) : null}
                  </Text>

                  <Text fontSize="sm" color={secondaryTextColor} noOfLines={2}>
                    {data.textNotification}
                  </Text>

                  <Text color={secondaryTextColor} as="sup" isTruncated>
                    {dayjs(data.createdAt).isValid()
                      ? dayjs(data.createdAt)
                          .locale(es)
                          .format("DD [de] MMMM [de] YYYY")
                      : "No Registrado"}
                  </Text>
                  <Text bg="bgBoxOutlet" as="sup" isTruncated>
                    {data.businessName}
                  </Text>
                </GridItem>
              </Grid>
            </RouterLink>
          </Box>
        ))}

      {notifications.length === 0 && (
        <Box textAlign="center" py={10} px={6} bg={bgBoxOutlet}>
          <Switch
            isChecked={colorMode === "dark"}
            /* onChange={toggleColorMode} */
            colorScheme="secondaryTextColor"
            bg={bgBoxOutlet}
            onClick={toggleColorMode}
            /* color={linkColor} */
            size="md"
            ml="0.5em"
          />
          <Box
            display="flex"
            justifyContent="center"
            width="full"
            bg={bgBoxOutlet}
            onClick={onClose}
          >
            <FiInfo size="50px" color="secondaryTextColor" />
          </Box>
          <Heading
            as="h2"
            size="lg"
            mt={6}
            mb={2}
            color={secondaryTextColor}
            borderBottom="1px solid #ccc"
          >
            No hay notificaciones disponibles por ahora
          </Heading>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cerrar
          </Button>
        </Box>
      )}
    </>
  );
};

export default NotificationCard;
