import { useEffect, useState } from 'react';
import { Formik, Field, Form, FormikProps, FieldProps } from 'formik';

import {
  Box,
  Select,
  Button,
  Input
} from '@chakra-ui/react';

import { FiFilter, FiX } from 'react-icons/fi';
import { useAuth } from '../../hooks/useAuth';
import { fetchAxios } from '../../axiosConfig';

interface Props {
  onSubmit: (values: FiltersFormValues) => void;
  resetForm: () => void;
};

export interface FiltersFormValues {
  license: string;
  app: string;
  status: string;
  search: string;
};

const FORM_INITIAL_VALUES: FiltersFormValues = {
  license: 'none',
  app: 'none',
  status: 'none',
  search: ''
};

const FiltersForm = ({ onSubmit, resetForm }: Props) => {

  const { authState } = useAuth();

  const [licenses, setLicenses] = useState([]);

  const getClientsLicense = async () => {

    try {

      const role = authState.user?.clientType === 'Bartik' ? 'admin' : 'user';
      const uri = `/client/get_licenses/${authState.user?.id}?role=${role}&type=get`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { 'authorization': `Bearer ${authState.token}` }
      });

      const { rows } = axiosResponse.data.dataInfo;

      setLicenses(rows);

    } catch (error) { setLicenses([]); }

  };

  const handleResetForm = (handleReset: any) => {
    resetForm();
    handleReset();
  };


  useEffect(() => {
    getClientsLicense();
  }, []);

  return (
    <Formik
      initialValues={FORM_INITIAL_VALUES}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<FiltersFormValues>) => (
        <Form>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              alignItems="center"
              width="full"
              mb="0.5em"
              flexDirection={{ base: 'column', md: 'row' }}
            >
            <Field name="license">
             {({ field }: FieldProps) => (
            <Select
              mr={{ base: '0', md: '0.5em' }}
              mb={{ base: '0.5em', md: '0' }}
                {...field}
                    >
              <option disabled value="none">
                {authState.user?.clientType === 'Bartik' ? 'Cliente' : 'Licencia'}
              </option>
              {licenses.map((item: any) => (
                <option
                  key={item.id}
                  value={item.id}
                  disabled={item.status === 0}
                >
                  {item.label} {item.status === 0 && '(Bloqueado)'}
                </option>
                ))}
              </Select>
              )}
                </Field>

                {/* <Field name="app">
                  {({ field }: FieldProps) => (
                <Select
                  mr={{ base: '0', md: '0.5em' }}
                  mb={{ base: '0.5em', md: '0' }}
                  {...field}
                >
                  <option disabled value="none">Aplicación</option>
                  <option value="Visitik Admin">Visitik Admin</option>
                  <option value="Visitik Operarios">Visitik Operarios</option>
                </Select>
              )}
            </Field> */}

              <Field name="status">
                 {({ field }: FieldProps) => (
                  <Select {...field}>
                    <option disabled value="none">Estado</option>
                    <option value={1}>Activo</option>
                    <option value={0}>Bloqueado</option>
                  </Select>
                    )}
                </Field>
              </Box>
            

            <Box
              display="flex"
              alignItems="center"
              width="full"
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Field name="search">
                {({ field }: FieldProps) => (
                  <Input
                    type="search"
                    placeholder="Buscar por: Nombre o N° de identificación"
                    spellCheck="false"
                    autoComplete="off"
                    {...field}
                  />
                )}
              </Field>

              <Button
                ml={{ base: '0', md: '0.5em' }}
                mt={{ base: '0.5em', md: '0' }}
                colorScheme="darkBlue"
                type="submit"
                leftIcon={<FiFilter />}
              >
                Filtrar
              </Button>

              <Button
                ml={{ base: '0', md: '0.5em' }}
                mt={{ base: '0.5em', md: '0' }}
                colorScheme="orange"
                type="button"
                leftIcon={<FiX />}
                onClick={() => handleResetForm(props.handleReset)}
              >
                Limpiar
              </Button>

            </Box>

          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default FiltersForm;
