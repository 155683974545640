import {
  Box,
  Spinner,
  Text
} from '@chakra-ui/react';

const LoadingScreen = () => {
  return (
    <Box
      pos="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      zIndex="100"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Spinner
        thickness="6px"
        speed="0.65s"
        emptyColor="greenDark.600"
        color="blue.700"
        size="xl"
      />

      <Text mt="1em">
        Cargando ...
      </Text>
    </Box>
  );
};

export default LoadingScreen;
