import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  Grid,
  GridItem,
  Alert,
  FormErrorMessage,
  AlertIcon,
  useToast,
  Box,
  useDisclosure,
  Spinner
} from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  fetchAxios,
  fetchMultimedia,
  formatHandledErrors,
} from "../../axiosConfig";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { doLogin, doImageUpload } from "../../actions/authActions";
import NoAvatar from "../../assets/images/no-avatar.png";
import UpdatePasswordUser from "./UpdatePasswordUser";
import LoadingScreen from "../LoadingScreen";

export interface formUsers {
  businessName: string;
  email: string;
  adress: string;
  country: string;
  city: string;
  managerName: string;
  mainPhone: string;
}
export interface Props {
  getUsersErrorMsg: string | null;
  users: formUsers;
  handleModalClose: () => void;
  changeError: (error: any) => void;
}

interface FormResetPasswordValues {
  oldPassword: string;
  password: string;
  confirm: string;
}

const FormUsersSchema = Yup.object().shape({
  businessName: Yup.string().required(
    "El nombre de la empresa es un campo obligatorio"
  ),
  email: Yup.string().required("El email es un campo obligatorio"),
  adress: Yup.string().required("La direccion es un campo obligatorio"),
  country: Yup.string().required("El pais es un campo obligatorio"),
  city: Yup.string().required("La ciudad es un campo obligatorio"),
  managerName: Yup.string().required(
    "El nombre del encargado es un campo obligatorio"
  ),
  mainPhone: Yup.string().required("El telefono es un campo obligatorio"),
});

export default function FormUpdateUser({
  handleModalClose,
  getUsersErrorMsg,
  users,
  changeError,
}: Props): JSX.Element {
  const { authState, dispatch } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
  const toast = useToast();

  const img = `${process.env.REACT_APP_IMAGES_API_URL}/image/get-image-profile/${authState.user?.image}`;

  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleUpdateUsers = async (value: formUsers) => {
    setIsLoading(true);
    try {
      const uri = `/user/update-users-perfil/${authState.user?.id}`;

      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: value,
      });

      const { type, message, user, isTemporalPass, token } =
        axiosResponse.data.dataInfo;

      dispatch(doLogin({ user, token, isTemporalPass, isLoggedIn: true }));

      window.localStorage.setItem("client_token", token);

      toast({
        title: type === "warning" ? "Atención" : "Perfil actualizado",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsLoading(false);

      handleModalClose();
    } catch (error: any) {
      setIsLoading(false);
      const formatedError = formatHandledErrors(error);
      toast({
        title: "Atencion",
        description: error.response.data.dataInfo.message
          ? error.response.data.dataInfo.message
          : error,
        status: error.response.data.dataInfo.type
          ? error.response.data.dataInfo.type
          : "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      changeError(formatedError);
    }
  };

  const handleUpdateUsersPhoto = async (e: any) => {
    setIsLoading(true);
    setIsLoadingAvatar(true);
    try {
      const file = e.target.files[0];

      const photo = authState.user?.image ? authState.user?.image : "";

      const formDataSend = new FormData();
      formDataSend.append("archivos", file);
      formDataSend.append("imgDelete", photo);

      const uriPhoto = `/image/update-image-perfil/${authState.user?.id}?typeUser=CLIENTS`;

      const response = await fetchMultimedia(uriPhoto, {
        method: "PUT",
        data: formDataSend,
      });

      const { filename, message } = response.data.dataInfo;
      dispatch(doImageUpload(filename));

      if (response.status === 201) {
        const clientToken = window.localStorage.getItem('client_token');

        const axiosResponse = await fetchAxios('/auth/refresh_token', {
          method: 'POST',
          data: { oldToken: clientToken }
        });

        const { newToken } = axiosResponse.data.dataInfo;

        window.localStorage.setItem('client_token', newToken);
      }

      toast({
        title: "Perfil actualizado",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      setIsLoading(false);
      setIsLoadingAvatar(false);
    } catch (error: any) {
      setIsLoading(false);
      const formatedError = formatHandledErrors(error);
      toast({
        title: "Atencion",
        description: error.response.data.dataInfo?.message
          ? error.response.data.dataInfo?.message
          : error,
        status: error.response.data.dataInfo?.type
          ? error.response.data.dataInfo?.type
          : "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      changeError(formatedError);
    }
  };

  const handleDeleteUsersPhoto = async () => {
    setIsLoading(true);
    try {
      const fileName = authState.user?.image ? authState.user?.image : "";

      const uri = `/user/delete-users-perfil-photo/${authState.user?.id}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: { fileName: fileName },
      });

      const { type, message, user, isTemporalPass, token } =
        axiosResponse.data.dataInfo;

      dispatch(doLogin({ user, token, isTemporalPass, isLoggedIn: true }));

      window.localStorage.setItem("client_token", token);

      toast({
        title: type === "warning" ? "Atención" : "Perfil actualizado",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      const formatedError = formatHandledErrors(error);
      toast({
        title: "Atencion",
        description: error.response.data.dataInfo?.message
          ? error.response.data.dataInfo?.message
          : error,
        status: error.response.data.dataInfo?.type
          ? error.response.data.dataInfo?.type
          : "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      changeError(formatedError);
    }
  };

  const handleSendForm = async (value: FormResetPasswordValues) => {
    try {
      const numberId = authState.user?.nit;
      const uri = `/user/change_password`;
      const axiosResponse = await fetchAxios(uri, {
        method: "POST",
        headers: { authorization: `Bearer ${authState.token}` },
        data: {
          numberId: numberId,
          password: value.password,
          confirm: value.confirm,
          oldPassword: value.oldPassword,
          type: "update",
        },
      });

      const { type, message } = axiosResponse.data.dataInfo;

      onClose();

      toast({
        title: type === "warning" ? "Atención" : "Perfil actualizado",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error: any) {
      toast({
        title: "Atencion",
        description: error.response.data.dataInfo?.message
          ? error.response.data.dataInfo?.message
          : error,
        status: error.response.data.dataInfo?.type
          ? error.response.data.dataInfo?.type
          : "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <Flex
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack
          spacing={4}
          w={"full"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          boxShadow={"lg"}
          p={6}
        >
          {getUsersErrorMsg && (
            <Alert status="error" mt="0.5em">
              <AlertIcon />
              {getUsersErrorMsg}
            </Alert>
          )}
          {users ? (
            <Formik
              initialValues={{
                businessName: users.businessName,
                email: users.email,
                adress: users.adress,
                country: users.country,
                city: users.city,
                managerName: users.managerName,
                mainPhone: users.mainPhone,
              }}
              onSubmit={handleUpdateUsers}
              validationSchema={FormUsersSchema}
            >
              {(props) => (
                <Form>
                  <Grid templateColumns="repeat(5, 1fr)">
                    <GridItem colSpan={2}>
                      <FormControl id="photo">
                        <FormLabel>Foto de perfil</FormLabel>
                        <Stack direction={["column", "row"]} spacing={2}>
                          <Center>
                            {
                              isLoadingAvatar ?
                                <Spinner
                                  size='xl'
                                  speed='0.25s'
                                /> :
                                <Avatar
                                  size="xl"
                                  src={authState.user?.image ? img : NoAvatar}
                                  ignoreFallback={true}
                                >
                                  <AvatarBadge
                                    as={IconButton}
                                    size="sm"
                                    rounded="full"
                                    top="-10px"
                                    colorScheme="red"
                                    aria-label="Eliminar Image"
                                    onClick={() => handleDeleteUsersPhoto()}
                                    icon={<SmallCloseIcon />}
                                  />
                                </Avatar>
                            }
                          </Center>
                          <Center>
                            <Input
                              w="150px"
                              h="auto"
                              p="0"
                              type={"file"}
                              onChange={(e) => handleUpdateUsersPhoto(e)}
                            />
                          </Center>
                        </Stack>
                      </FormControl>

                      {/* <Text>Tipo de cliente : Empresarial</Text> */}

                      <Field name="businessName">
                        {({ field, form }: any) => (
                          <FormControl
                            mb="0.8em"
                            isInvalid={
                              form.errors.businessName &&
                              form.touched.businessName
                            }
                            isDisabled={props.isSubmitting}
                          >
                            <FormLabel htmlFor="businessName">
                              Nombre de la empresa
                            </FormLabel>

                            <Input
                              type={"text"}
                              placeholder="Ingrese un nombre"
                              autoComplete="off"
                              spellCheck="false"
                              disabled={true}
                              {...field}
                            />

                            <FormErrorMessage>
                              {form.errors.businessName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="email">
                        {({ field, form }: any) => (
                          <FormControl
                            mb="0.8em"
                            isInvalid={form.errors.email && form.touched.email}
                            isDisabled={props.isSubmitting}
                          >
                            <FormLabel htmlFor="email">Email</FormLabel>

                            <Input
                              type={"text"}
                              placeholder="Ingrese su email"
                              autoComplete="off"
                              spellCheck="false"
                              {...field}
                            />

                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Box marginTop={"50px"}>
                        <Button
                          w="full"
                          whiteSpace="normal"
                          onClick={() => onOpen()}
                        >
                          Cambiar contraseña
                        </Button>
                      </Box>
                    </GridItem>

                    <GridItem colStart={4} colEnd={4}>
                      <Field name="adress">
                        {({ field, form }: any) => (
                          <FormControl
                            mb="0.8em"
                            isInvalid={
                              form.errors.adress && form.touched.adress
                            }
                            isDisabled={props.isSubmitting}
                          >
                            <FormLabel htmlFor="adress">Direccion</FormLabel>

                            <Input
                              type={"text"}
                              placeholder="Ingrese su Direccion"
                              autoComplete="off"
                              spellCheck="false"
                              disabled={true}
                              {...field}
                            />

                            <FormErrorMessage>
                              {form.errors.adress}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="country">
                        {({ field, form }: any) => (
                          <FormControl
                            mb="0.8em"
                            isInvalid={
                              form.errors.conuntry && form.touched.conuntry
                            }
                            isDisabled={props.isSubmitting}
                          >
                            <FormLabel htmlFor="country">Pais</FormLabel>

                            <Input
                              type={"text"}
                              placeholder="Ingrese su pais"
                              autoComplete="off"
                              spellCheck="false"
                              disabled={true}
                              {...field}
                            />

                            <FormErrorMessage>
                              {form.errors.country}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="city">
                        {({ field, form }: any) => (
                          <FormControl
                            mb="0.8em"
                            isInvalid={
                              form.errors.conuntry && form.touched.conuntry
                            }
                            isDisabled={props.isSubmitting}
                          >
                            <FormLabel htmlFor="city">Ciudad</FormLabel>

                            <Input
                              type={"text"}
                              placeholder="Ingrese su ciudad"
                              autoComplete="off"
                              spellCheck="false"
                              disabled={true}
                              {...field}
                            />

                            <FormErrorMessage>
                              {form.errors.city}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="managerName">
                        {({ field, form }: any) => (
                          <FormControl
                            mb="0.8em"
                            isInvalid={
                              form.errors.conuntry && form.touched.conuntry
                            }
                            isDisabled={props.isSubmitting}
                          >
                            <FormLabel htmlFor="managerName">
                              Nombre del encargado
                            </FormLabel>

                            <Input
                              type={"text"}
                              placeholder="Ingrese su nombre"
                              autoComplete="off"
                              spellCheck="false"
                              {...field}
                            />

                            <FormErrorMessage>
                              {form.errors.managerName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="mainPhone">
                        {({ field, form }: any) => (
                          <FormControl
                            mb="0.8em"
                            isInvalid={
                              form.errors.conuntry && form.touched.conuntry
                            }
                            isDisabled={props.isSubmitting}
                          >
                            <FormLabel htmlFor="mainPhone">Telefono</FormLabel>

                            <Input
                              type={"text"}
                              placeholder="Ingrese su telefono"
                              autoComplete="off"
                              spellCheck="false"
                              {...field}
                            />

                            <FormErrorMessage>
                              {form.errors.mainPhone}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <GridItem colSpan={1}>
                        <Stack spacing={6} direction={["column", "row"]}>
                          <Button
                            onClick={() => handleModalClose()}
                            isDisabled={isLoading}
                          >
                            Cancelar
                          </Button>
                          <Button
                            type="submit"
                            isLoading={isLoading}
                            isDisabled={isLoading}
                          >
                            Actualizar
                          </Button>
                        </Stack>
                      </GridItem>
                    </GridItem>
                  </Grid>
                </Form>
              )}
            </Formik>
          ) : (
            <Box>
              <LoadingScreen />
            </Box>
          )}
        </Stack>
      </Flex>

      <UpdatePasswordUser
        isOpen={isOpen}
        onClose={onClose}
        handleSendForm={handleSendForm}
      />
    </>
  );
}
