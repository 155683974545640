import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./context/AuthContext";
import { NotificationsProvider } from "./context/NotificationsContext";
import AppRouter from "./routers/AppRouter";
import theme from "./theme";

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <NotificationsProvider>
          <AppRouter />
        </NotificationsProvider>
      </AuthProvider>
    </ChakraProvider>
  );
};

export default App;
