import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { fetchAxios, formatHandledErrors } from "../../axiosConfig";

interface Props {
  handleUpdateUsersList: (newUser: any) => void;
  closeModal: () => void;
}

interface NewUserValues {
  clientId?: number;
  licenseId: number;
  numberId: string;
  name: string;
  email: string;
  rol: number;
  typeOperator: number;
  contactPhone: string;
}

const INITIAL_VALUES_FORM: NewUserValues = {
  clientId: 0,
  licenseId: 0,
  numberId: "",
  name: "",
  email: "",
  rol: 0,
  typeOperator: 0,
  contactPhone: "",
};

const FormNewUserSchema = Yup.object().shape({
  clientId: Yup.lazy((value) => {
    if (value !== 0 && value !== null) {
      return Yup.mixed().notOneOf([null], "Debes seleccionar un cliente");
    }
    return Yup.mixed().notRequired();
  }),
  licenseId: Yup.mixed().notOneOf([0, null], "Debes seleccionar una licencia"),
  numberId: Yup.string().required(
    "El número de identificación es un campo obligatorio"
  ),
  name: Yup.string().required("El nombre es un campo obligatorio"),
  email: Yup.string()
    .email("Debes ingresar un email válido")
    .required("El email es un campo obligatorio"),
  rol: Yup.mixed().notOneOf([0, null], "Debes seleccionar un rol"),
  // typeOperator: Yup.mixed().notOneOf(
  //   [0, null],
  //   "Debes seleccionar un tipo de operador valido"
  // ),
  contactPhone: Yup.string().required(
    "El número de contacto es un campo obligatorio"
  ),
});

const FormNewUserSchemaOperario = Yup.object().shape({
  clientId: Yup.lazy((value) => {
    if (value !== 0 && value !== null) {
      return Yup.mixed().notOneOf([null], "Debes seleccionar un cliente");
    }
    return Yup.mixed().notRequired();
  }),
  licenseId: Yup.mixed().notOneOf([0, null], "Debes seleccionar una licencia"),
  numberId: Yup.string().required(
    "El número de identificación es un campo obligatorio"
  ),
  name: Yup.string().required("El nombre es un campo obligatorio"),
  email: Yup.string()
    .email("Debes ingresar un email válido")
    .required("El email es un campo obligatorio"),
  rol: Yup.mixed().notOneOf([0, null], "Debes seleccionar un rol"),
  typeOperator: Yup.mixed().notOneOf(
    [0, null],
    "Debes seleccionar un tipo de operador valido"
  ),
  contactPhone: Yup.string().required(
    "El número de contacto es un campo obligatorio"
  ),
});

const FormNewUser = ({ closeModal, handleUpdateUsersList }: Props) => {
  const toast = useToast();
  const { authState } = useAuth();
  const [clients, setClients] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [type, settype] = useState([]);
  const [typeOperator, setTypeOperator] = useState([]);
  const [typeOperador, settypeOperador] = useState<any>("");  

  const handleSendNewUser = async (values: NewUserValues) => {
    try {
      const uri = `/user/create_new_user/${authState.user?.id}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "POST",
        headers: { authorization: `Bearer ${authState.token}` },
        data: values,
      });

      const { message, userId, aditionalData } = axiosResponse.data.dataInfo;

      const newUser = {
        id: userId,
        numberID: values.numberId,
        name: values.name,
        imageUrl: null,
        appName: aditionalData.appName,
        status: 1,
        email: values.email,
        customName:
          authState.user?.clientType === "Bartik"
            ? aditionalData.businessName
            : aditionalData.customName,
        keyLicense:
          authState.user?.clientType === "Bartik"
            ? ""
            : aditionalData.keyLicense,
      };

      if (aditionalData.appName !== "Visitik Operarios") {
        handleUpdateUsersList(newUser);
      }

      toast({
        title: "Usuario creado",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: false,
        position: "top-right",
      });

      closeModal();
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);

      toast({
        title: "Error",
        description: formatedError,
        duration: 5000,
        isClosable: true,
        position: "top-right",
        status: "error",
      });
    }
  };

  const getClients = async () => {
    try {
      const uri = `/client/get_licenses/${authState.user?.id}?role=admin`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { rows } = axiosResponse.data.dataInfo;

      setClients(rows);
    } catch (error) {
      setClients([]);
    }
  };

  const getLicenses = async (idClient: number, rol: string) => {
    try {
      const uri = `/client/get_licenses/${idClient}?role=${rol}`;

      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { rows } = axiosResponse.data.dataInfo;

      setLicenses(rows);
    } catch (error) {
      setLicenses([]);
    }
  };

  const getRol = async () => {
    try {
      const uri = `/type/get-type/`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { rows } = axiosResponse.data.dataInfo;

      settype(rows);
    } catch (error) {
      settype([]);
    }
  };

  const getTypeOperator = async () => {
    try {
      const uri = `/type/get-type-operador/`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { rows } = axiosResponse.data.dataInfo;

      setTypeOperator(rows);
    } catch (error) {
      settype([]);
    }
  };

  useEffect(() => {
    if (authState.user?.clientType === "Bartik") {
      getClients();
      getRol();
      getTypeOperator();
    } else {
      getLicenses(authState.user?.id || 0, "user");
      getRol();
      getTypeOperator();
    }
  }, [authState]);

  const prueba = async (idRol: number) => {
    const results = await type.find((data: any) => data.id === Number(idRol));
    if (results) {
      settypeOperador(results);
    }
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES_FORM}
      validationSchema={
        typeOperador?.roleName === "Operador"
          ? FormNewUserSchemaOperario
          : FormNewUserSchema
      }
      onSubmit={handleSendNewUser}
    >
      {(props) => (
        <Form>
          <ModalBody>
            {authState.user?.clientType === "Bartik" && (
              <Field name="clientId">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.clientId && form.touched.clientId}
                    mb="0.5em"
                  >
                    <FormLabel htmlFor="clientId">Cliente</FormLabel>
                    <Select
                      id="clientId"
                      name={field.name}
                      value={field.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(e);
                        getLicenses(parseInt(value), "Bartik");
                      }}
                    >
                      <option disabled value={0}>
                        Selecciona un cliente
                      </option>
                      {clients.map((client: any) => (
                        <option
                          key={client.id}
                          value={client.id}
                          disabled={client.status === 0}
                        >
                          {client.label} -{" "}
                          {client.status === 0 && "(Bloqueado)"}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{form.errors.clientId}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}

            <Field name="licenseId">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.licenseId && form.touched.licenseId}
                  mb="0.5em"
                >
                  <FormLabel htmlFor="licenseId">Licencia asociada</FormLabel>
                  <Select {...field} id="licenseId">
                    <option disabled value={0}>
                      Selecciona una licencia
                    </option>
                    {licenses.map((license: any) => (
                      <option
                        key={license.id}
                        value={license.id}
                        disabled={license.status === 0}
                      >
                        {license.label} -{" "}
                        {license.status === 0 && "(Bloqueado)"}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.licenseId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="rol">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.rol && form.touched.rol}
                  mb="0.5em"
                >
                  <FormLabel htmlFor="rol">Selecciona un rol</FormLabel>
                  <Select
                    {...field}
                    id="rol"
                    onClick={() => prueba(field.value)}
                  >
                    <option disabled value={0}>
                      Selecciona un rol
                    </option>
                    {type.map((types: any) => (
                      <option key={types.id} value={types.id}>
                        {types.roleName}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.rol}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {typeOperador?.roleName === "Operador" && (
              <Field name="typeOperator">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={
                      form.errors.typeOperator && form.touched.typeOperator
                    }
                    mb="0.5em"
                  >
                    <FormLabel htmlFor="typeOperator">
                      Selecciona el tipo de operador
                    </FormLabel>
                    <Select {...field} id="typeOperator">
                      <option disabled value={0}>
                        Selecciona un tipo de operador
                      </option>
                      {typeOperator.map((types: any) => (
                        <option key={types.id} value={types.id}>
                          {types.typeName}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {form.errors.typeOperator}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}

            <Field name="numberId">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.numberId && form.touched.numberId}
                  mb="0.5em"
                >
                  <FormLabel htmlFor="numberId">
                    Número de identificación
                  </FormLabel>
                  <Input
                    type="text"
                    id="numberId"
                    placeholder="Número de identificación"
                    autoComplete="off"
                    spellCheck="false"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.numberId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="name">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.name && form.touched.name}
                  mb="0.5em"
                >
                  <FormLabel htmlFor="name">Nombre Completo</FormLabel>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Nombre completo"
                    autoComplete="off"
                    spellCheck="false"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="email">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  mb="0.5em"
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    type="email"
                    id="email"
                    placeholder="Email"
                    autoComplete="off"
                    spellCheck="false"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="contactPhone">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={
                    form.errors.contactPhone && form.touched.contactPhone
                  }
                  isDisabled={props.isSubmitting}
                  isRequired
                >
                  <FormLabel htmlFor="contactPhone">
                    Número de contacto
                  </FormLabel>
                  <Input
                    {...field}
                    id="contactPhone"
                    placeholder="123456789"
                    spellCheck="false"
                    autoComplete="off"
                  />
                  <FormErrorMessage>
                    {form.errors.contactPhone}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={closeModal}
              isDisabled={props.isSubmitting}
              mr="0.5em"
            >
              Cerrar
            </Button>

            <Button
              type="submit"
              colorScheme="darkBlue"
              isLoading={props.isSubmitting}
              isDisabled={props.isSubmitting}
            >
              Guardar
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default FormNewUser;
