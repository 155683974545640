import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const colors = {
  brand: {
    50: "#f6fbe1",
    100: "#e8f0bd",
    200: "#d9e697",
    300: "#cadd6f",
    400: "#bad348",
    500: "#a1b92e",
    600: "#7d9022",
    700: "#596717",
    800: "#353e0b",
    900: "#111600",
  },
  greenDark: {
    50: "#f0fae4",
    100: "#d8eac5",
    200: "#c1daa3",
    300: "#a9cb7f",
    400: "#91bc5c",
    500: "#78a343",
    600: "#5c7f33",
    700: "#415a23",
    800: "#263712",
    900: "#091400",
  },
  gray: {
    50: "#f8f0f2",
    100: "#d9d9d9",
    200: "#bfbfbf",
    300: "#a6a6a6",
    400: "#8c8c8c",
    500: "#737373",
    600: "#595959",
    700: "#404040",
    800: "#262626",
    900: "#120b0d",
  },
  whiteSmoke: "#f5f5f5",
  darkBlue: {
    50: "#eaf1fc",
    100: "#c9d5e8",
    200: "#a7bad6",
    300: "#849ec6",
    400: "#6382b6",
    500: "#49699d",
    600: "#39527b",
    700: "#283a58",
    800: "#172336",
    900: "#050c16",
  },
};

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: true,
};

const styles = {
  global: (props: any) => ({
    body: {
      bg: mode("whiteSmoke", "gray.900")(props),
    },
  }),
};

const theme = extendTheme({ colors, config, styles });

export default theme;
