import { useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Button } from '@chakra-ui/react';

const NotFound = () => {

  const navigate = useNavigate();

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        fontSize={{ base: '10em', md: '14em' }}
        bgGradient="linear(to-r, greenDark.400, greenDark.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="2em" mt={3} mb={2}>
        Página no encontrada
      </Text>
      <Text fontSize="1.2em" color={'gray.500'} mb={6}>
        La página que estas buscando no existe
      </Text>

      <Button
        colorScheme="greenDark"
        bgGradient="linear(to-r, greenDark.400, greenDark.500, greenDark.600)"
        color="white"
        variant="solid"
        onClick={() => navigate('/app/home')}
      >
        Ir al inicio
      </Button>
    </Box>
  );
};

export default NotFound;