import { Box } from "@chakra-ui/layout";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import DashboardLayout from "../layout/DashboardLayout";
import MainLayout from "../layout/MainLayout";
import Home from "../pages/Home";
import Licenses from "../pages/Licenses";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Options from "../pages/SendRepost";
import RecoveryMessage from "../pages/RecoveryMessage";
import RecoveryPassword from "../pages/RecoveryPassword";
import RequestDetails from "../pages/RequestDetails";
import RequestList from "../pages/RequestList";
import SubUser from "../pages/SubUsers";
import Users from "../pages/Users";
import ReportDetails from "../pages/ReportDetail";
import MarketingView from "../pages/MarketingView";

const AppRouter = () => {
  return (
    <>
      <BrowserRouter>
        <Box height="100vh" width="100%">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/recovery" element={<RecoveryPassword />} />
              <Route
                path="/recovery_sended/:numberId"
                element={<RecoveryMessage />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
            {/* Private Routes */}
            <Route path="/app" element={<DashboardLayout />}>
              <Route
                path="/app/home"
                element={
                  <RequireAuth>
                    <Home />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/client"
                element={
                  <RequireAuth>
                    <Users />
                  </RequireAuth>
                }
              />

              <Route
                path="/app/user"
                element={
                  <RequireAuth>
                    <SubUser />
                  </RequireAuth>
                }
              />

              <Route
                path="/app/licenses"
                element={
                  <RequireAuth>
                    <Licenses />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/report"
                element={
                  <RequireAuth>
                    <Options />
                  </RequireAuth>
                }
              />

              <Route
                path="/app/request-details/:id"
                element={
                  <RequireAuth>
                    <RequestDetails />
                  </RequireAuth>
                }
              />

              <Route
                path="/app/request"
                element={
                  <RequireAuth>
                    <RequestList />
                  </RequireAuth>
                }
              />

              <Route
                path="/app/report-details/:id"
                element={
                  <RequireAuth>
                    <ReportDetails />
                  </RequireAuth>
                }
              />

              <Route
                path="/app/marketing"
                element={
                  <RequireAuth>
                    <MarketingView />
                  </RequireAuth>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Box>
      </BrowserRouter>
    </>
  );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { authState } = useAuth();
  const location = useLocation();

  if (!authState.isLoggedIn)
    return <Navigate to="/" state={{ from: location }} />;

  return children;
};

export default AppRouter;
