import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  useColorModeValue,
  Alert,
  AlertIcon,
  Image,
  Stack,
  Text,
  Button,
  Divider,
  ButtonGroup,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";

import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import CardFooter from "../components/Card/CardFooter";
import { Formik, Form, Field } from "formik";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import { useAuth } from "../hooks/useAuth";

export interface FormNewLicenseValues {
  clientId: string;
}

const FORM_NEW_LICENSE_INITIAL_VALUES: FormNewLicenseValues = {
  clientId: "",
};
const FormNewLicenseSchema = Yup.object().shape({
  clientId: Yup.string().required("Debes selecciona un tipo de cliente válido"),
});

export default function MarketingView() {
  const { authState } = useAuth();
  const toast = useToast();

  const {
    isOpen: isOpenModalStatus,
    onOpen: onOpenModalStatus,
    onClose: onCloseModalStatus,
  } = useDisclosure();

  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [clients, setClients] = useState([]);
  const [getClientsError, setGetClientsError] = useState<string | null>(null);

  const handleSubmitForm = async (values: FormNewLicenseValues) => {
    try {
      setIsLoadingStatus(true);

      const uri = `/marketing/email_send_install_app/${
        authState.user?.id
      }/${Number(values.clientId)}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "GET",
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { message, type } = axiosResponse.data.dataInfo;

      toast({
        title:
          type === "warning" ? "Ocurrio un problema" : "Licencias generadas",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsLoadingStatus(false);

      onCloseModalStatus();
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);

      toast({
        title: "Error",
        description: error.response.data?.dataInfo?.message
          ? error.response.data.dataInfo.message
          : formatedError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsLoadingStatus(false);
    }
  };

  const getClients = async () => {
    try {
      setIsLoadingStatus(true);
      const uri = `/marketing/get_clients_structure/${authState.user?.id}`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { listClients } = axiosResponse.data.dataInfo;

      setClients(listClients);
      setIsLoadingStatus(false);
    } catch (error: any) {
      setIsLoadingStatus(false);
      const formatedError = formatHandledErrors(error);
      setGetClientsError(formatedError);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
      >
        <Heading>Marketing</Heading>
      </Box>

      <Box
        bg={useColorModeValue("white", "gray.700")}
        padding="1em"
        borderRadius="10px"
        mt="1em"
      >
        <Box width="full" display="flex" flexDirection="column">
          <Card maxW="sm">
            <CardBody>
              <Image
                src="https://bartikcontainer.s3.amazonaws.com/image_bartik/visitik/email+bartik.png"
                alt="Email activations visitik residente"
                boxSize="250px"
                w={"full"}
                objectFit="cover"
                borderRadius="lg"
              />
              <Stack mt="4" spacing="3">
                <Heading size="md">
                  Codigo de activacion de visitik residente
                </Heading>
                <Text>
                  Al optar por esta alternativa, se procederá a remitir un
                  correo electrónico a los usuarios residentes del cojunto
                  residencial que haya seleccionado, con la finalidad de
                  informarles que ya cuentan con la opción de instalar Visitik
                  Residentes. Asimismo, se incluirá en dicho correo el código de
                  registro correspondiente al apartamento o casa en cuestión.
                </Text>
              </Stack>
            </CardBody>
            <Center height="15px">
              <Divider bg="gray" />
            </Center>
            <CardFooter>
              <ButtonGroup>
                <Button
                  variant="solid"
                  colorScheme="blue"
                  onClick={onOpenModalStatus}
                >
                  Enviar email
                </Button>
              </ButtonGroup>
            </CardFooter>
          </Card>
        </Box>
      </Box>

      <Modal
        isCentered
        onClose={onCloseModalStatus}
        isOpen={isOpenModalStatus}
        motionPreset="slideInBottom"
        closeOnEsc={!isLoadingStatus}
        closeOnOverlayClick={!isLoadingStatus}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            Selecciona un cliente
          </ModalHeader>
          <ModalCloseButton disabled={isLoadingStatus} />

          <Formik
            initialValues={FORM_NEW_LICENSE_INITIAL_VALUES}
            validationSchema={FormNewLicenseSchema}
            onSubmit={handleSubmitForm}
          >
            {(props) => (
              <Form>
                <ModalBody>
                  <Field name="clientId">
                    {({ field, form }: any) => (
                      <FormControl
                        mb="0.8em"
                        isInvalid={
                          form.errors.clientId && form.touched.clientId
                        }
                        isDisabled={props.isSubmitting}
                      >
                        <FormLabel htmlFor="clientId">Cliente</FormLabel>
                        <AutoComplete
                          openOnFocus
                          rollNavigation
                          id="clientId"
                          value={field.value}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                        >
                          <AutoCompleteInput placeholder="Selecciona un cliente" />
                          <AutoCompleteList>
                            {clients.map((item: any) => (
                              <AutoCompleteItem
                                key={String(item.id)}
                                value={item}
                                label={`${item.nit} | ${item.businessName} | ${item.divisionName}`}
                              >
                                {item.nit} | {item.businessName} |{" "}
                                {item.divisionName}
                              </AutoCompleteItem>
                            ))}
                          </AutoCompleteList>
                        </AutoComplete>

                        {getClientsError && (
                          <Alert status="error" mt="0.5em">
                            <AlertIcon />
                            {getClientsError}
                          </Alert>
                        )}

                        <FormErrorMessage>
                          {form.errors.clientId}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </ModalBody>

                <ModalFooter>
                  <Button
                    variant="ghost"
                    mr={3}
                    onClick={onCloseModalStatus}
                    isDisabled={isLoadingStatus}
                  >
                    Cerrar
                  </Button>
                  <Button
                    type="submit"
                    colorScheme="darkBlue"
                    isLoading={isLoadingStatus}
                    isDisabled={isLoadingStatus}
                    //   onClick={handleChangeUserStatus}
                  >
                    {"Enviar correo"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
}
