import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  fetchAxios,
  formatHandledErrors,
  fetchMultimedia,
} from "../../axiosConfig";
import { useAuth } from "../../hooks/useAuth";
import * as Yup from "yup";

export interface FormMessageValues {
  nit: string;
  titulo: string;
  mensaje: string;
  file: string;
}

const FORM_MESSAGE_LICENSE_INITIAL_VALUES: FormMessageValues = {
  nit: "",
  titulo: "",
  mensaje: "",
  file: "",
};

const FormMessageSchema = Yup.object().shape({
  nit: Yup.string().notOneOf(
    ["", null],
    "Debes selecciona un tipo de cliente válido"
  ),
  titulo: Yup.string().required("El titulo es un campo obligatorio"),
  file: Yup.string().notOneOf(
    ["", null],
    "Debes selecciona un tipo de cliente válido"
  ),
});

interface Props {
  onClose: () => void;
  getSendReport: () => void;
}

export default function NewMessage({ onClose, getSendReport }: Props) {
  const { authState } = useAuth();

  const [clients, setClients] = useState<any>([]);
  const [getClientsError, setGetClientsError] = useState<string | null>(null);
  const [archivo, setarchivo] = useState<any>({});
  const toast = useToast();

  const getClients = async () => {
    try {
      const rol =
        authState.user?.clientType === "Empresarial"
          ? "user"
          : "userIndividual";

      const uri = `/client/get_clients/${authState.user?.id}?rol=${rol}`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { rows } = axiosResponse.data.dataInfo;

      setClients(rows);
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      setGetClientsError(formatedError);
    }
  };

  const sendReport = async (value: FormMessageValues) => {
    try {
      const formDataFile = new FormData();
      formDataFile.append("archivos", archivo[0]);

      const uriFile = `/document/upload-files`;
      const response = await fetchMultimedia(uriFile, {
        method: "POST",
        data: formDataFile,
      });

      const { status, data } = response;

      if (status === 201) {
        const uri = `/repots/send-reports/${authState.user?.nit}?typesend=Adminitradores`;
        const axiosResponse = await fetchAxios(uri, {
          method: "POST",
          headers: { authorization: `Bearer ${authState.token}` },
          data: {
            title: value.titulo,
            archivos: data.dataInfo.addFile,
            text: value.mensaje,
            nitSend: value.nit,
            tipoMessage: "Informes",
          },
        });

        const { message, type } = axiosResponse.data.dataInfo;

        toast({
          title: type === "warning" ? "Atención" : "Informe enviado con exito",
          description: message,
          status: type,
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });

        getSendReport();
        onClose();
      }
    } catch (error: any) {
      toast({
        title: "Atención",
        description:
          error.response.data.errors || error.response.data.dataInfo.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <Formik
      initialValues={FORM_MESSAGE_LICENSE_INITIAL_VALUES}
      onSubmit={sendReport}
      validationSchema={FormMessageSchema}
    >
      {(props) => (
        <Form>
          <ModalBody>
            <Field name="nit">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.nit && form.touched.nit}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="idsubClient">Cliente</FormLabel>
                  <AutoComplete
                    openOnFocus
                    rollNavigation
                    id="idsubClient"
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                  >
                    <AutoCompleteInput placeholder="Selecciona un cliente" />
                    <AutoCompleteList>
                      {clients.map((item: any) => (
                        <AutoCompleteItem
                          key={String(item.id)}
                          value={String(item.nit)}
                          label={`${item.nit} | ${item.businessName}`}
                        >
                          {item.nit} | {item.businessName}
                        </AutoCompleteItem>
                      ))}
                    </AutoCompleteList>
                  </AutoComplete>

                  {getClientsError && (
                    <Alert status="error" mt="0.5em">
                      <AlertIcon />
                      {getClientsError}
                    </Alert>
                  )}

                  <FormErrorMessage>{form.errors.nit}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="titulo">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.titulo && form.touched.titulo}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="titulo">Titulo</FormLabel>

                  <Input
                    type={"text"}
                    placeholder="Ingrese un titulo"
                    autoComplete="off"
                    spellCheck="false"
                    {...field}
                  />

                  {getClientsError && (
                    <Alert status="error" mt="0.5em">
                      <AlertIcon />
                      {getClientsError}
                    </Alert>
                  )}

                  <FormErrorMessage>{form.errors.titulo}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="mensaje">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.mensaje && form.touched.mensaje}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="mensaje">Mensaje</FormLabel>

                  <Textarea
                    {...field}
                    type={"text"}
                    placeholder="Ingrese el mensaje"
                  ></Textarea>

                  {getClientsError && (
                    <Alert status="error" mt="0.5em">
                      <AlertIcon />
                      {getClientsError}
                    </Alert>
                  )}

                  <FormErrorMessage>{form.errors.mensaje}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="file">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.file && form.touched.file}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="file">Informe</FormLabel>

                  <Input
                    type={"file"}
                    h="auto"
                    w="150px"
                    p="0"
                    name="file"
                    onChange={(e) => setarchivo(e.target.files)}
                  />

                  {getClientsError && (
                    <Alert status="error" mt="0.5em">
                      <AlertIcon />
                      {getClientsError}
                    </Alert>
                  )}

                  <FormErrorMessage>{form.errors.file}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {archivo[0] ? archivo[0].name : null}
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              isDisabled={props.isSubmitting}
              mr="0.5em"
            >
              Cerrar
            </Button>

            <Button
              type="submit"
              colorScheme="darkBlue"
              isLoading={props.isSubmitting}
              isDisabled={props.isSubmitting}
            >
              Enviar
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}
