import { AuthState } from "../interfaces/authInterface";

export type AuthActions =
  | { type: '[AUTH]: Login', payload: AuthState }
  | { type: '[AUTH]: Logout' }
  | {type: '[AUTH]: ImageUpload', payload: string };

export const doLogin = (loginInfo: AuthState): AuthActions => ({
  type: '[AUTH]: Login',
  payload: loginInfo
});

export const doLogout = (): AuthActions => ({ type: '[AUTH]: Logout' });

export const doImageUpload = (image: string): AuthActions => ({
  type: '[AUTH]: ImageUpload',
  payload: image
});