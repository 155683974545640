import React, { useState } from "react";

import {
  Box,
  Text,
  Image,
  IconButton,
  useColorModeValue,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  useToast,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  Heading,
  AccordionIcon,
  AccordionPanel,
  Table,
  Thead,
  Tr,
  Th,
  Tfoot,
  Badge,
  Td,
  Tbody,
  Tag,
  PopoverContent,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  Stack,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  PopoverTrigger,
} from "@chakra-ui/react";

import { FiCopy, FiEdit, FiMail } from "react-icons/fi";
import NoImage from "../../assets/images/no-avatar.png";
import dayjs from "dayjs";
import es from "dayjs/locale/es";
import { useAuth } from "../../hooks/useAuth";
import FocusLock from "react-focus-lock";
import {
  fetchAxios,
  fetchMultimedia,
  formatHandledErrors,
} from "../../axiosConfig";
import { BiBlock, BiLockOpenAlt, BiX, BiCheck } from "react-icons/bi";

const { useCopyToClipboard } = require("react-use-copy-to-clipboard");

interface Props {
  id: number;
  nit: string;
  businessName: string;
  email?: string;
  image?: string;
  status: number;
  clientType: string;
  clientsParent: string;
  clientsParentNit: string;
  licenses: License[] | [];
  updateStatus: (numberId: string, newStatus: number) => void;
  updateEmailClient: (id: number, newEmail: string) => void;
  handleUpdateUserImage: (numberId: string, image: any) => void;
}

interface License {
  idlicenses: number;
  keyLicense: string;
  appName: string;
  statusKey: string;
  version: string;
  expirationDate: string;
}

const UserCard = (props: Props) => {
  const {
    id,
    nit,
    image,
    businessName,
    status,
    email,
    licenses,
    clientsParent,
    clientsParentNit,
    updateEmailClient,
    updateStatus,
    handleUpdateUserImage,
  } = props;

  const toast = useToast();
  const { authState } = useAuth();

  const img = `${process.env.REACT_APP_IMAGES_API_URL}/image/get-image-profile/${image}`;

  const colorBgTemporalPass = useColorModeValue("whiteSmoke", "gray.400");

  const bgAccordionExpanded = useColorModeValue("darkBlue.800", "darkBlue.300");
  const colorAccordionExpanded = useColorModeValue("white", "gray.700");
  const emailColor = useColorModeValue("gray.500", "gray.300");
  const emailColorExpanded = useColorModeValue("whiteSmoke", "gray.600");
  const borderColor = useColorModeValue("gray.100", "gray.600");
  const borderTopColor = useColorModeValue("gray.100", "gray.600");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const firstFieldRef = React.useRef(null);

  const {
    isOpen: isOpenModalStatus,
    onOpen: onOpenModalStatus,
    onClose: onCloseModalStatus,
  } = useDisclosure();

  const {
    isOpen: isOpenModalRecoveryPass,
    // onOpen: onOpenModalRecoveryPass,
    onClose: onCloseModalRecoveryPass,
  } = useDisclosure();

  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [temporalPass, setTemporalPass] = useState<string | null>(null);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const clickRefCopy = useCopyToClipboard(
    temporalPass,
    () => {
      toast({
        title: "Texto copiado!",
        description: "Copiado!",
        status: "info",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    },
    () => {
      toast({
        title: "Error",
        description: "No se puedo copiar el texto",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  );

  const handleCloseModalRecoveryPass = () => {
    setTemporalPass(null);
    onCloseModalRecoveryPass();
  };

  const TextInputEmail = React.forwardRef((props: any, ref) => {
    return (
      <FormControl>
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <Input ref={ref} id={props.id} {...props} />
      </FormControl>
    );
  });

  const FormChangeEmail = ({
    firstFieldRef,
    onCancel,
    defaultEmail,
    clientId,
    updateEmailClient,
  }: any) => {
    const toast = useToast();
    const { authState } = useAuth();

    const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);

    const handleUpdateEmail = async () => {
      const emailReference = firstFieldRef.current;

      if (!emailReference) return;

      setIsUpdatingEmail(true);

      try {
        const uri = `/client/update_client_email/${clientId}`;
        const axiosResponse = await fetchAxios(uri, {
          method: "PUT",
          headers: { authorization: `Bearer ${authState.token}` },
          data: { newEmail: emailReference.value },
        });

        const { message } = axiosResponse.data.dataInfo;

        toast({
          title: "Email actualizado",
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });

        updateEmailClient(clientId, emailReference.value);
        setIsUpdatingEmail(false);
        onCancel();
      } catch (error: any) {
        const formatedError = formatHandledErrors(error);

        toast({
          title: "Error",
          description: formatedError,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });

        setIsUpdatingEmail(false);
      }
    };

    return (
      <Stack spacing={4}>
        <TextInputEmail
          label="Email"
          id="email"
          ref={firstFieldRef}
          defaultValue={defaultEmail}
        />
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button
            variant="outline"
            isDisabled={isUpdatingEmail}
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleUpdateEmail}
            isDisabled={isUpdatingEmail}
            colorScheme="greenDark"
          >
            Guardar
          </Button>
        </ButtonGroup>
      </Stack>
    );
  };

  const handleSendWelcomeEmail = async () => {
    setIsSendingEmail(true);

    try {
      const uri = `/client/send_welcome_email/${id}`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { message } = axiosResponse.data.dataInfo;

      toast({
        title: "Email enviado",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSendingEmail(false);
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);

      toast({
        title: "Error",
        description: formatedError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSendingEmail(false);
    }
  };

  const handleChangeUserStatus = async () => {
    setIsLoadingStatus(true);

    try {
      const newStatus = status === 1 ? 0 : 1;

      const uri = `/client/change_clients_status/${nit}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: { newStatus },
      });

      const { message } = axiosResponse.data.dataInfo;

      toast({
        title: "Usuario actualizado",
        description: message,
        status: "success",
        isClosable: true,
        duration: 5000,
        position: "top-right",
      });

      updateStatus(nit, newStatus);

      setIsLoadingStatus(false);
      onCloseModalStatus();
    } catch (error: any) {
      setIsLoadingStatus(false);
      const formatedMessage = formatHandledErrors(error);

      toast({
        title: "Error",
        description: formatedMessage,
        duration: 5000,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleUpdateUsersPhoto = async (e: any) => {
    try {
      const file = e.target.files[0];

      const photo = image ? image : "";

      const formDataSend = new FormData();

      formDataSend.append("archivos", file);
      formDataSend.append("imgDelete", photo);
      formDataSend.append("type", "sub");

      const uriMultimedia = `/image/update-image-perfil/${id}?typeUser=CLIENTS`;
      const response = await fetchMultimedia(uriMultimedia, {
        method: "PUT",
        data: formDataSend,
      });
      const { filename, message } = response.data.dataInfo;

      handleUpdateUserImage(nit, filename);

      toast({
        title: "Perfil actualizado",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error: any) {
      toast({
        title: "Atencion",
        description: error.response.data?.errors,
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <Accordion
        allowToggle
        width="full"
        mb="1em"
        sx={{
          ":last-child": {
            mb: "0",
          },
        }}
      >
        <AccordionItem
          borderColor={borderColor}
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="5px"
          key={id}
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton
                py="0.8em"
                borderTopRadius="5px"
                _expanded={{
                  bg: bgAccordionExpanded,
                  color: colorAccordionExpanded,
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  width="100% "
                  sx={{
                    "@media (max-width: 600px)": {
                      flexDir: "column",
                      alignItems: "baseline",
                    },
                  }}
                >
                  <Image
                    src={image ? img : NoImage}
                    alt={`Seguridad Penta LTDA Logo`}
                    mr="1em"
                    width="60px"
                    borderRadius="5px"
                  />
                  <Box textAlign="left">
                    <Heading size="md">{businessName}</Heading>
                    <Text>
                      <b>NIT:</b> {nit}
                    </Text>

                    <Text
                      fontSize="0.9em"
                      color={isExpanded ? emailColorExpanded : emailColor}
                    >
                      {email}
                    </Text>
                  </Box>

                  {authState.user?.clientType === "Bartik" && (
                    <Box
                      textAlign="left"
                      ml="auto"
                      mr="0.5em"
                      sx={{
                        "@media (max-width: 600px)": {
                          marginLeft: 0,
                          marginRight: 0,
                        },
                      }}
                    >
                      <Heading size="md">
                        Cliente padre: {clientsParent}
                      </Heading>
                      <Text>
                        <b>NIT:</b> {clientsParentNit}
                      </Text>
                    </Box>
                  )}

                  <AccordionIcon fontSize="1.8em" ml="auto" mr="0.5em" />
                </Box>
              </AccordionButton>
              <AccordionPanel p="1em">
                <Box
                  display="flex"
                  sx={{
                    "@media (max-width: 900px)": {
                      flexDir: "column",
                    },
                  }}
                >
                  <Button
                    variant="outline"
                    size="sm"
                    mr="0.5em"
                    leftIcon={<FiMail />}
                    loadingText="Enviando"
                    isLoading={isSendingEmail}
                    onClick={handleSendWelcomeEmail}
                    sx={{
                      "@media (max-width: 900px)": {
                        marginBottom: 5,
                      },
                    }}
                  >
                    Reenviar correo de bienvenida
                  </Button>

                  <Popover
                    isOpen={isOpen}
                    initialFocusRef={firstFieldRef}
                    onOpen={onOpen}
                    onClose={onClose}
                    //placement='top'
                    closeOnBlur={false}
                  >
                    <PopoverTrigger>
                      <Button
                        variant="outline"
                        size="sm"
                        mr="0.5em"
                        leftIcon={<FiEdit />}
                        sx={{
                          "@media (max-width: 900px)": {
                            marginBottom: 5,
                          },
                        }}
                      >
                        Editar Email
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent p={5}>
                      <FocusLock returnFocus persistentFocus={false}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <FormChangeEmail
                          firstFieldRef={firstFieldRef}
                          onCancel={onClose}
                          defaultEmail={email}
                          clientId={id}
                          updateEmailClient={updateEmailClient}
                        />
                      </FocusLock>
                    </PopoverContent>
                  </Popover>

                  <Button
                    variant="outline"
                    size="sm"
                    mr="0.5em"
                    sx={{
                      "@media (max-width: 900px)": {
                        marginBottom: 5,
                      },
                    }}
                    colorScheme={status === 1 ? "red" : "green"}
                    leftIcon={
                      <Icon as={status === 1 ? BiBlock : BiLockOpenAlt} />
                    }
                    loadingText="Enviando"
                    isLoading={isSendingEmail}
                    onClick={onOpenModalStatus}
                  >
                    {status === 1
                      ? "Bloquear Subcliente"
                      : "Desbloquear Subcliente"}
                  </Button>
                  <Input
                    width="150px"
                    h="auto"
                    p="0"
                    type={"file"}
                    onChange={(e) => handleUpdateUsersPhoto(e)}
                  />
                </Box>

                {/* TODO: agregar opcion de editar email */}

                <Box mt="1em">
                  <Box
                    borderWidth="thin"
                    borderStyle="solid"
                    padding="0.5em"
                    borderColor={borderTopColor}
                    borderRadius="10px"
                    sx={{
                      "@media (max-width: 600px)": {
                        overflowX: "scroll",
                      },
                    }}
                  >
                    <Table variant="simple" size="md" overflowX="auto">
                      <Thead>
                        <Tr>
                          <Th>Clave de licencia</Th>
                          <Th>Aplicacion</Th>
                          <Th>Version</Th>
                          <Th>Estado</Th>
                          <Th isNumeric>Fecha de expiración</Th>
                        </Tr>
                      </Thead>

                      <Tbody>
                        {licenses.map((license: License) => (
                          <Tr key={license.keyLicense}>
                            <Td>{license.keyLicense}</Td>
                            <Td>{license.appName}</Td>
                            <Td>
                              <Tag size="sm" variant="outline">
                                {license.version}
                              </Tag>
                            </Td>
                            <Td>
                              {license.statusKey === "EXPIRED" ? (
                                <Badge colorScheme="orange">EXPIRADA</Badge>
                              ) : license.statusKey === "IN USE" ? (
                                <Badge colorScheme="darkBlue">EN USO</Badge>
                              ) : (
                                <Badge>DISPONIBLE</Badge>
                              )}
                            </Td>
                            <Td isNumeric>
                              {!license.expirationDate
                                ? "NO REGISTRADO"
                                : dayjs(license.expirationDate)
                                    .locale(es)
                                    .format("DD/MM/YYYY")}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>

                      <Tfoot>
                        <Tr>
                          <Th>Clave de licencia</Th>
                          <Th>Aplicacion</Th>
                          <Th>Version</Th>
                          <Th>Estado</Th>
                          <Th isNumeric>Fecha de expiración</Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </Box>
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>

      <Modal
        isCentered
        onClose={onCloseModalStatus}
        isOpen={isOpenModalStatus}
        motionPreset="slideInBottom"
        closeOnEsc={!isLoadingStatus}
        closeOnOverlayClick={!isLoadingStatus}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            {status === 1 ? "Bloquear" : "Activar"} Usuario
            <Box ml="0.3em" mt="0.2em">
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg={status === 1 ? "red.500" : "green.500"}
                rounded={"50px"}
                w={"15px"}
                h={"15px"}
                textAlign="center"
              >
                <Icon
                  as={status === 1 ? BiX : BiCheck}
                  fontSize="15px"
                  color={"white"}
                />
              </Flex>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text display="inline-block">
              ¿Estas seguro de {status === 1 ? "Bloquear" : "Activar"} al
              usuario:{" "}
              <Text as="span" fontWeight="500">
                name
              </Text>
              ?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={onCloseModalStatus}
              isLoading={isLoadingStatus}
              isDisabled={isLoadingStatus}
            >
              Cerrar
            </Button>
            <Button
              colorScheme="darkBlue"
              isLoading={isLoadingStatus}
              isDisabled={isLoadingStatus}
              onClick={handleChangeUserStatus}
            >
              {status === 1 ? "Bloquear" : "Activar"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        onClose={handleCloseModalRecoveryPass}
        isOpen={isOpenModalRecoveryPass}
        motionPreset="slideInBottom"
        closeOnEsc={!isLoadingStatus}
        closeOnOverlayClick={!isLoadingStatus}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Restaurar contraseña del usuario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              fontSize="0.95em"
              color={useColorModeValue("gray.400", "gray.300")}
            >
              *Al restaurar la contraseña de un usuario se enviará un correo con
              la nueva contraseña, además de que podras copiarla y enviarla al
              usuario si es necesario
            </Text>
            {!!temporalPass && (
              <Box
                width="full"
                my="0.7em"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="5px"
                padding="5px 10px"
                bg={colorBgTemporalPass}
              >
                <Text
                  ml="1em"
                  letterSpacing="3px"
                  fontWeight="500"
                  fontSize="1.1em"
                >
                  {temporalPass}
                </Text>
                <IconButton
                  aria-label="Copiar codigo"
                  variant="ghost"
                  icon={<FiCopy />}
                  ref={clickRefCopy}
                />
              </Box>
            )}
            <Button
              mt="0.5em"
              colorScheme="darkBlue"
              isLoading={isLoadingStatus}
              isDisabled={!!temporalPass}
              // onClick={handleSendNewPassword}
            >
              Restaurar Contraseña
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              onClick={handleCloseModalRecoveryPass}
              isLoading={isLoadingStatus}
              isDisabled={isLoadingStatus}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserCard;
