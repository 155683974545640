import {
  Badge,
  Box,
  Button,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import es from "dayjs/locale/es";
import { FaClock } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";

interface ReportProps {
  count: number;
  // updateCount: (id: any) => void;
  report: [];
  isLoading: boolean;
}

export default function ReportsCard({ count, report, isLoading }: ReportProps) {
  const bgBoxOutlet = useColorModeValue("gray.50", "whiteAlpha.50");
  const { colorMode } = useColorMode();

  return (
    <Box>
      {report.length !== 0 &&
        report.map((data: any, index: any) => (
          <RouterLink
            to={`/app/report-details/${data.idMessage}`}
            // onClick={() => updateCount(data.id)}
            key={index}
          >
            <Stack
              bg={colorMode === "dark" ? bgBoxOutlet : "white"}
              p="4"
              boxShadow="dark-lg"
              m="4"
              borderRadius="sm"
              _hover={{ shadow: "2xl" }}
              key={data.id}
            >
              <Stack direction="row" alignItems="center">
                <Text fontWeight="semibold">{data.title}</Text>
                {data.readed === 1 && (
                  <Badge ml="1" fontSize="0.8em" colorScheme="green">
                    Sin leer
                  </Badge>
                )}
                <FaClock />
              </Stack>

              <Stack
                direction={{ base: "column", md: "row" }}
                justifyContent="space-between"
              >
                <Text fontSize={{ base: "sm" }} textAlign={"left"} maxW={"4xl"}>
                  Envio de informe
                </Text>
                <Stack direction={{ base: "column", md: "row" }}>
                  <Button colorScheme="green">ver mas</Button>
                </Stack>
              </Stack>
              <Text fontSize={14}>
                {dayjs(data.createdAt).isValid()
                  ? dayjs(data.createdAt)
                      .locale(es)
                      .format("DD [de] MMMM [de] YYYY")
                  : "No Registrado"}
              </Text>

              <Text>Cliente : {data.businessName}</Text>
            </Stack>
          </RouterLink>
        ))}
    </Box>
  );
}
