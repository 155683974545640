import { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  Text,
  Image,
  IconButton,
  Badge,
  useColorModeValue,
  Icon,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  useToast,
  useDisclosure,
  Input,
  FormLabel,
  Avatar,
  AvatarBadge,
  FormErrorMessage,
  FormControl,
  Accordion,
  AccordionItem,
  AccordionButton,
  Heading,
  AccordionIcon,
  AccordionPanel,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
} from "@chakra-ui/react";
import NoAvatar from "../../assets/images/no-avatar.png";
import {
  BiX,
  BiCheck,
  BiEdit,
  BiKey,
  BiBlock,
  BiLockOpenAlt,
} from "react-icons/bi";
import { FiCopy } from "react-icons/fi";
import { SmallCloseIcon } from "@chakra-ui/icons";
import fallbackImage from "../../assets/images/no-avatar.png";
import { useAuth } from "../../hooks/useAuth";
import {
  fetchAxios,
  fetchAxiosImg,
  formatHandledErrors,
} from "../../axiosConfig";

const { useCopyToClipboard } = require("react-use-copy-to-clipboard");

interface Props {
  idUsers: number;
  numberId: string;
  name: string;
  email?: string;
  image?: string;
  application: string;
  status: number;
  license?: any;
  contactPhone?: string;
  changeUpdateUser: (
    id: any,
    numberId: string,
    name: string,
    email: string,
    phone: string
  ) => void;
  updateStatus: (numberId: string, newStatus: number) => void;
  changeUpdateUserImagen: (id: any, image: any) => void;
}

const SubUserCard = (props: Props) => {
  const {
    idUsers,
    numberId,
    image,
    name,
    status,
    email,
    application,
    license,
    contactPhone,
    updateStatus,
    changeUpdateUser,
    changeUpdateUserImagen,
  } = props;

  const FormUpdateClientSchema = Yup.object().shape({
    numberId: Yup.string().required(
      "El NIT | Número de identificación es un campo obligatorio"
    ),
    email: Yup.string()
      .email("Ingresa un email válido")
      .required("El email es un campo obligatorio"),
    name: Yup.string().required(
      "El nombre de contacto es un campo obligatorio"
    ),
    contactPhone: Yup.string().required(
      "El número de contacto es un campo obligatorio"
    ),
  });
  const toast = useToast();
  const { authState } = useAuth();

  const imageUrl = image
    ? `${process.env.REACT_APP_IMAGES_API_URL}/image/get-image-profile/${image}`
    : fallbackImage;

  const {
    isOpen: isOpenModalStatus,
    onOpen: onOpenModalStatus,
    onClose: onCloseModalStatus,
  } = useDisclosure();

  const {
    isOpen: isOpenModalRecoveryPass,
    onOpen: onOpenModalRecoveryPass,
    onClose: onCloseModalRecoveryPass,
  } = useDisclosure();

  const {
    isOpen: isOpenModalEditUser,
    onOpen: onOpenModalEdituser,
    onClose: onCloseModalEdituser,
  } = useDisclosure();

  const bgAccordionExpanded = useColorModeValue("darkBlue.800", "darkBlue.300");
  const colorAccordionExpanded = useColorModeValue("white", "gray.700");
  const emailColor = useColorModeValue("gray.500", "gray.300");
  const emailColorExpanded = useColorModeValue("whiteSmoke", "gray.600");
  const borderColor = useColorModeValue("gray.100", "gray.600");
  const borderTopColor = useColorModeValue("gray.100", "gray.600");
  const colorBgTemporalPass = useColorModeValue("whiteSmoke", "gray.400");

  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [temporalPass, setTemporalPass] = useState<string | null>(null);

  const clickRefCopy = useCopyToClipboard(
    temporalPass,
    () => {
      toast({
        title: "Texto copiado!",
        description: "Copiado!",
        status: "info",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    },
    () => {
      toast({
        title: "Error",
        description: "No se puedo copiar el texto",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  );

  const handleCloseModalRecoveryPass = () => {
    setTemporalPass(null);
    onCloseModalRecoveryPass();
  };

  const handleChangeUserStatus = async () => {
    setIsLoadingStatus(true);

    try {
      const newStatus = status === 1 ? 0 : 1;

      const uri = `/user/change_user_status/${numberId}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: { newStatus },
      });

      const { message } = axiosResponse.data.dataInfo;

      toast({
        title: "Usuario actualizado",
        description: message,
        status: "success",
        isClosable: true,
        duration: 5000,
        position: "top-right",
      });

      updateStatus(numberId, newStatus);

      setIsLoadingStatus(false);
      onCloseModalStatus();
    } catch (error: any) {
      setIsLoadingStatus(false);
      const formatedMessage = formatHandledErrors(error);

      toast({
        title: "Error",
        description: formatedMessage,
        duration: 5000,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleSendNewPassword = async () => {
    setIsLoadingStatus(true);

    try {
      const uri = `/user/recovery_password_user/${numberId}`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { message, newPassword } = axiosResponse.data.dataInfo;

      toast({
        title: "Usuario actualizado",
        description: message,
        status: "success",
        isClosable: true,
        duration: 5000,
        position: "top-right",
      });

      setTemporalPass(newPassword);
      setIsLoadingStatus(false);
    } catch (error: any) {
      setIsLoadingStatus(false);
      const formatedMessage = formatHandledErrors(error);

      toast({
        title: "Error",
        description: formatedMessage,
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "top-right",
      });
    }
  };

  const updateUserOperador = async (value: any) => {
    setIsLoadingUpdate(true);
    try {
      const uri = `/user/update-operador/${authState.user?.id}/${numberId}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: {
          numberId: value.numberId,
          name: value.name,
          email: value.email,
          contactPhone: value.contactPhone,
        },
      });

      const { message, dataInfo } = axiosResponse.data.dataInfo;

      changeUpdateUser(
        dataInfo.id,
        dataInfo.numberId,
        dataInfo.name,
        dataInfo.email,
        dataInfo.contactPhone
      );

      onCloseModalEdituser();
      setIsLoadingUpdate(false);

      toast({
        title: "Usuario actualizado",
        description: message,
        status: "success",
        isClosable: true,
        duration: 5000,
        position: "top-right",
      });
    } catch (error: any) {
      setIsLoadingUpdate(false);
      const formatedMessage = formatHandledErrors(error);
      toast({
        title: "Error",
        description: error.response.data.dataInfo.message
          ? error.response.data.dataInfo.message
          : formatedMessage,
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "top-right",
      });
    }
  };

  const handleUpdateUsersPhoto = async (e: any) => {
    try {
      const file = e.target.files[0];

      const photo = image ? image : "";

      const formDataSend = new FormData();
      formDataSend.append("archivos", file);
      formDataSend.append("imgDelete", photo);

      const uri = `/image/update-image-perfil/${idUsers}?typeUser=ADMIN`;
      const axiosResponse = await fetchAxiosImg(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: formDataSend,
      });

      const { message, filename } = axiosResponse.data.dataInfo;

      changeUpdateUserImagen(idUsers, filename);

      toast({
        title: "Perfil actualizado",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      toast({
        title: "Atencion",
        description: error.response.data.dataInfo?.message
          ? error.response.data.dataInfo?.message
          : formatedError,
        status: error.response.data.dataInfo?.type
          ? error.response.data.dataInfo?.type
          : "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <>
        <Accordion
          allowToggle
          width="full"
          mb="1em"
          sx={{
            ":last-child": {
              mb: "0",
            },
          }}
        >
          <AccordionItem
            borderColor={borderColor}
            borderWidth="1px"
            borderStyle="solid"
            borderRadius="5px"
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  py="0.8em"
                  borderTopRadius="5px"
                  _expanded={{
                    bg: bgAccordionExpanded,
                    color: colorAccordionExpanded,
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100% "
                    sx={{
                      "@media (max-width: 600px)": {
                        flexDir: "column",
                        alignItems: "baseline",
                      },
                    }}
                  >
                    <Image
                      src={NoAvatar}
                      alt={`User ${name}`}
                      width="60px"
                      height="60px"
                      objectFit="cover"
                      borderRadius="full"
                    />
                    <Box textAlign="left" width={"100%"} marginLeft="1rem">
                      <Heading size="md"> {name}</Heading>
                      <Text fontSize="0.9em">D.N.I : {numberId}</Text>
                      <Text as="span" fontWeight="200" overflow="hidden">
                        Email : {email}
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection={{ base: "row", md: "column" }}
                      alignItems="center"
                      mt={{ base: "1em", md: "0" }}
                      width="100%"
                    >
                      <Badge
                        variant="subtle"
                        colorScheme={status === 1 ? "green" : "red"}
                        mr={{ base: "0.5em", md: "0" }}
                        mb={{ base: "0", md: "0.3em" }}
                      >
                        {status === 1 ? "ACTIVO" : "BLOQUEADO"}
                      </Badge>
                      <Tag
                        variant="outline"
                        textAlign="center"
                        color={isExpanded ? emailColorExpanded : emailColor}
                        border={isExpanded ? emailColorExpanded : emailColor}
                      >
                        {application}
                      </Tag>
                    </Box>

                    <AccordionIcon fontSize="1.8em" ml="0.5em" mr="0.5em" />
                  </Box>
                </AccordionButton>
                <AccordionPanel p="1em">
                  {/* TODO: agregar opcion de editar email */}

                  <Box
                    mt={{ base: "1em", md: "0" }}
                    display="flex"
                    alignItems="center"
                  >
                    {authState.user?.clientType !== "Bartik" && (
                      <Button
                        variant="outline"
                        size="sm"
                        mr="0.5em"
                        sx={{
                          "@media (max-width: 900px)": {
                            marginBottom: 5,
                          },
                        }}
                        isDisabled={status === 0}
                        colorScheme="green"
                        leftIcon={<Icon as={BiEdit} />}
                        onClick={onOpenModalEdituser}
                      >
                        Editar usuario
                      </Button>
                    )}

                    <Button
                      variant="outline"
                      size="sm"
                      mr="0.5em"
                      sx={{
                        "@media (max-width: 900px)": {
                          marginBottom: 5,
                        },
                      }}
                      isDisabled={status === 0}
                      colorScheme="darkBlue"
                      leftIcon={<Icon as={BiKey} />}
                      onClick={
                        status === 1 ? onOpenModalRecoveryPass : () => null
                      }
                    >
                      Cambiar contraseña
                    </Button>

                    <Button
                      variant="outline"
                      size="sm"
                      mr="0.5em"
                      sx={{
                        "@media (max-width: 900px)": {
                          marginBottom: 5,
                        },
                      }}
                      colorScheme={status === 1 ? "red" : "green"}
                      leftIcon={
                        <Icon as={status === 1 ? BiBlock : BiLockOpenAlt} />
                      }
                      onClick={onOpenModalStatus}
                    >
                      {status === 1
                        ? "Bloquear usuario"
                        : "Desbloquear usuario"}
                    </Button>
                  </Box>

                  <Box mt="1em">
                    <Box
                      borderWidth="thin"
                      borderStyle="solid"
                      padding="0.5em"
                      borderColor={borderTopColor}
                      borderRadius="10px"
                      sx={{
                        "@media (max-width: 600px)": {
                          overflowX: "scroll",
                        },
                      }}
                    >
                      <Table
                        variant="simple"
                        size="md"
                        overflowX="auto"
                        textAlign={"center"}
                      >
                        <Thead>
                          <Tr>
                            <Th>Cliente</Th>
                            <Th>Clave</Th>
                            <Th>Nombre de la licencia</Th>
                          </Tr>
                        </Thead>

                        <Tbody>
                          {license.map((data: any) => (
                            <Tr key={String(data.keyLicense)}>
                              <Td>{data.businessName}</Td>
                              <Td>{data.keyLicense}</Td>
                              <Td>{data.customName}</Td>
                            </Tr>
                          ))}
                        </Tbody>

                        <Tfoot>
                          <Tr>
                            <Th>Cliente</Th>
                            <Th>Clave</Th>
                            <Th>Nombre de la licencia</Th>
                          </Tr>
                        </Tfoot>
                      </Table>
                    </Box>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        <Modal
          isCentered
          onClose={onCloseModalStatus}
          isOpen={isOpenModalStatus}
          motionPreset="slideInBottom"
          closeOnEsc={!isLoadingStatus}
          closeOnOverlayClick={!isLoadingStatus}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader display="flex" alignItems="center">
              {status === 1 ? "Bloquear" : "Activar"} Usuario
              <Box ml="0.3em" mt="0.2em">
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  bg={status === 1 ? "red.500" : "green.500"}
                  rounded={"50px"}
                  w={"15px"}
                  h={"15px"}
                  textAlign="center"
                >
                  <Icon
                    as={status === 1 ? BiX : BiCheck}
                    fontSize="15px"
                    color={"white"}
                  />
                </Flex>
              </Box>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text display="inline-block">
                ¿Estas seguro de {status === 1 ? "Bloquear" : "Activar"} al
                usuario:{" "}
                <Text as="span" fontWeight="500">
                  name
                </Text>
                ?
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={onCloseModalStatus}
                isLoading={isLoadingStatus}
                isDisabled={isLoadingStatus}
              >
                Cerrar
              </Button>
              <Button
                colorScheme="darkBlue"
                isLoading={isLoadingStatus}
                isDisabled={isLoadingStatus}
                onClick={handleChangeUserStatus}
              >
                {status === 1 ? "Bloquear" : "Activar"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isCentered
          onClose={handleCloseModalRecoveryPass}
          isOpen={isOpenModalRecoveryPass}
          motionPreset="slideInBottom"
          closeOnEsc={!isLoadingStatus}
          closeOnOverlayClick={!isLoadingStatus}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Restaurar contraseña del usuario</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text
                fontSize="0.95em"
                color={useColorModeValue("gray.400", "gray.300")}
              >
                *Al restaurar la contraseña de un usuario se enviará un correo
                con la nueva contraseña, además de que podras copiarla y
                enviarla al usuario si es necesario
              </Text>
              {!!temporalPass && (
                <Box
                  width="full"
                  my="0.7em"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="5px"
                  padding="5px 10px"
                  bg={colorBgTemporalPass}
                >
                  <Text
                    ml="1em"
                    letterSpacing="3px"
                    fontWeight="500"
                    fontSize="1.1em"
                  >
                    {temporalPass}
                  </Text>
                  <IconButton
                    aria-label="Copiar codigo"
                    variant="ghost"
                    icon={<FiCopy />}
                    ref={clickRefCopy}
                  />
                </Box>
              )}
              <Button
                mt="0.5em"
                colorScheme="darkBlue"
                isLoading={isLoadingStatus}
                isDisabled={!!temporalPass}
                // onClick={handleSendNewPassword}
              >
                Restaurar Contraseña
              </Button>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="ghost"
                onClick={handleCloseModalRecoveryPass}
                isLoading={isLoadingStatus}
                isDisabled={isLoadingStatus}
              >
                Cerrar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>

      {/* <SimpleGrid
        columns={[1, null, 4]}
        borderRadius="0.4em"
        borderColor={useColorModeValue("gray.100", "gray.600")}
        borderWidth="thin"
        padding="0.8em 1.2em"
        alignItems="center"
        transition="background-color .3s ease, background-color .2s ease"
        marginBottom="0.5em"
        sx={{ ":last-child": { marginBottom: 0 } }}
        _hover={{
          backgroundColor: useColorModeValue("whiteSmoke", "gray.600"),
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          width="140%"
          sx={{
            "@media (max-width: 600px)": {
              width: "100%",
            },
          }}
        >
          <Image
            src={imageUrl}
            alt={`User ${name}`}
            width="60px"
            height="60px"
            objectFit="cover"
            borderRadius="full"
          />

          <Box ml="1em" wordBreak="break-word">
            <Text
              color={useColorModeValue("gray.700", "gray.100")}
              fontSize="1.15em"
              fontWeight="500"
            >
              {name}
            </Text>
            <Text fontSize="0.9em">D.N.I : {numberId}</Text>
            <Text as="span" fontWeight="200" overflow="hidden">
              Email : {email}
            </Text>
          </Box>
        </Box>

        <Box
          mt={{ base: "1em", md: "0" }}
          ml={{ base: "0", md: "7em" }}
          width="110%"
          fontSize="1em"
        >
          <Text>
            <Text as="span" fontWeight="500" color={colorBoldText}>
              {authState.user?.clientType === "Bartik" ? "Cliente" : "Licencia"}
              :
            </Text>{" "}
            {authState.user?.clientType === "Bartik"
              ? businessName
              : licenseName}
          </Text>
          {license && (
            <Text>
              <Text as="span" fontWeight="500" color={colorBoldText}>
                Clave:
              </Text>{" "}
              {license}
            </Text>
          )}
        </Box>

        <Box
          display="flex"
          width="50%"
          flexDirection={{ base: "row", md: "column" }}
          alignItems="center"
          mt={{ base: "1em", md: "0" }}
          ml={{ base: "0", md: "13em" }}
        >
          <Badge
            variant="subtle"
            colorScheme={status === 1 ? "green" : "red"}
            mr={{ base: "0.5em", md: "0" }}
            mb={{ base: "0", md: "0.3em" }}
          >
            {status === 1 ? "ACTIVO" : "BLOQUEADO"}
          </Badge>
          <Tag variant="outline" textAlign="center">
            {application}
          </Tag>
        </Box>

        <Box
          ml={{ base: "0", md: "auto" }}
          mt={{ base: "1em", md: "0" }}
          display="flex"
          alignItems="center"
        >
          {authState.user?.clientType !== "Bartik" && (
            <IconButton
              variant="outline"
              colorScheme="green"
              aria-label={"Editar usuario"}
              icon={<Icon as={BiEdit} />}
              onClick={onOpenModalEdituser}
            />
          )}

          <IconButton
            ml="0.5em"
            variant="outline"
            colorScheme="darkBlue"
            aria-label="Cambiar contraseña"
            icon={<Icon as={BiKey} />}
            isDisabled={status === 0}
            onClick={status === 1 ? onOpenModalRecoveryPass : () => null}
          />
          <IconButton
            ml="0.5em"
            variant="outline"
            colorScheme={status === 1 ? "red" : "green"}
            aria-label={status === 1 ? "Bloquear usuario" : "Activar usuario"}
            icon={<Icon as={status === 1 ? BiBlock : BiLockOpenAlt} />}
            onClick={onOpenModalStatus}
          />
        </Box>
      </SimpleGrid> */}

      <Modal
        isCentered
        onClose={onCloseModalEdituser}
        isOpen={isOpenModalEditUser}
        motionPreset="slideInBottom"
        closeOnEsc={!isLoadingStatus}
        closeOnOverlayClick={!isLoadingStatus}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            Editar Usuario
            <Box ml="0.3em" mt="0.2em">
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                rounded={"50px"}
                w={"15px"}
                h={"15px"}
                textAlign="center"
              ></Flex>
            </Box>
          </ModalHeader>
          <ModalCloseButton />

          <Formik
            initialValues={{
              numberId: numberId,
              name: name,
              email: email,
              contactPhone: contactPhone,
            }}
            validationSchema={FormUpdateClientSchema}
            onSubmit={updateUserOperador}
          >
            {(props) => (
              <Form>
                <ModalBody>
                  <Avatar
                    size="xl"
                    src={authState.user?.image ? imageUrl : NoAvatar}
                  >
                    <AvatarBadge
                      as={IconButton}
                      size="sm"
                      rounded="full"
                      top="-10px"
                      colorScheme="red"
                      aria-label="Eliminar Image"
                      icon={<SmallCloseIcon />}
                    />
                  </Avatar>
                  <Input
                    w="150px"
                    h="auto"
                    p="0"
                    mt="30px"
                    ml="60px"
                    type={"file"}
                    onChange={(e) => handleUpdateUsersPhoto(e)}
                  />

                  <Field name="numberId">
                    {({ field, form }: any) => (
                      <FormControl
                        mb="0.8em"
                        isInvalid={
                          form.errors.numberId && form.touched.numberId
                        }
                        isDisabled={props.isSubmitting}
                      >
                        <FormLabel htmlFor="numberId">
                          Número de identificación
                        </FormLabel>
                        <Input
                          {...field}
                          id="numberId"
                          type="text"
                          placeholder={numberId}
                          spellCheck="false"
                          autoComplete="off"
                        />
                        <FormErrorMessage>
                          {form.errors.numberId}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="name">
                    {({ field, form }: any) => (
                      <FormControl
                        mb="0.8em"
                        isInvalid={form.errors.name && form.touched.name}
                        isDisabled={props.isSubmitting}
                      >
                        <FormLabel htmlFor="name">Nombre completo</FormLabel>
                        <Input
                          {...field}
                          id="name"
                          type="text"
                          placeholder={name}
                          spellCheck="false"
                          autoComplete="off"
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="email">
                    {({ field, form }: any) => (
                      <FormControl
                        mb="0.8em"
                        isInvalid={form.errors.email && form.touched.email}
                        isDisabled={props.isSubmitting}
                      >
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Input
                          {...field}
                          id="email"
                          type="email"
                          placeholder={email}
                          spellCheck="false"
                          autoComplete="off"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="contactPhone">
                    {({ field, form }: any) => (
                      <FormControl
                        mb="0.8em"
                        isInvalid={
                          form.errors.contactPhone && form.touched.contactPhone
                        }
                        isDisabled={props.isSubmitting}
                      >
                        <FormLabel htmlFor="contactPhone">
                          Número de contacto
                        </FormLabel>
                        <Input
                          {...field}
                          id="contactPhone"
                          type="number"
                          spellCheck="false"
                          autoComplete="off"
                        />
                        <FormErrorMessage>
                          {form.errors.contactPhone}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </ModalBody>

                <ModalFooter>
                  <Button
                    variant="ghost"
                    mr={3}
                    onClick={onCloseModalEdituser}
                    isDisabled={isLoadingUpdate}
                  >
                    Cerrar
                  </Button>

                  <Button
                    type="submit"
                    colorScheme="green"
                    isLoading={isLoadingUpdate}
                    isDisabled={isLoadingUpdate}
                  >
                    Guardar
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        onClose={onCloseModalStatus}
        isOpen={isOpenModalStatus}
        motionPreset="slideInBottom"
        closeOnEsc={!isLoadingStatus}
        closeOnOverlayClick={!isLoadingStatus}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            {status === 1 ? "Bloquear" : "Activar"} Usuario
            <Box ml="0.3em" mt="0.2em">
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg={status === 1 ? "red.500" : "green.500"}
                rounded={"50px"}
                w={"15px"}
                h={"15px"}
                textAlign="center"
              >
                <Icon
                  as={status === 1 ? BiX : BiCheck}
                  fontSize="15px"
                  color={"white"}
                />
              </Flex>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text display="inline-block">
              ¿Estas seguro de {status === 1 ? "Bloquear" : "Activar"} al
              usuario:{" "}
              <Text as="span" fontWeight="500">
                {name}
              </Text>
              ?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={onCloseModalStatus}
              isLoading={isLoadingStatus}
              isDisabled={isLoadingStatus}
            >
              Cerrar
            </Button>
            <Button
              colorScheme="darkBlue"
              isLoading={isLoadingStatus}
              isDisabled={isLoadingStatus}
              onClick={handleChangeUserStatus}
            >
              {status === 1 ? "Bloquear" : "Activar"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        onClose={handleCloseModalRecoveryPass}
        isOpen={isOpenModalRecoveryPass}
        motionPreset="slideInBottom"
        closeOnEsc={!isLoadingStatus}
        closeOnOverlayClick={!isLoadingStatus}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Restaurar contraseña del usuario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              fontSize="0.95em"
              color={useColorModeValue("gray.400", "gray.300")}
            >
              *Al restaurar la contraseña de un usuario se enviará un correo con
              la nueva contraseña, además de que podras copiarla y enviarla al
              usuario si es necesario
            </Text>
            {!!temporalPass && (
              <Box
                width="full"
                my="0.7em"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="5px"
                padding="5px 10px"
                bg={colorBgTemporalPass}
              >
                <Text
                  ml="1em"
                  letterSpacing="3px"
                  fontWeight="500"
                  fontSize="1.1em"
                >
                  {temporalPass}
                </Text>
                <IconButton
                  aria-label="Copiar codigo"
                  variant="ghost"
                  icon={<FiCopy />}
                  ref={clickRefCopy}
                />
              </Box>
            )}
            <Button
              mt="0.5em"
              colorScheme="darkBlue"
              isLoading={isLoadingStatus}
              isDisabled={!!temporalPass}
              onClick={handleSendNewPassword}
            >
              Restaurar Contraseña
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              onClick={handleCloseModalRecoveryPass}
              isLoading={isLoadingStatus}
              isDisabled={isLoadingStatus}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubUserCard;
