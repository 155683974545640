import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  ModalBody,
  ModalFooter,
  useToast,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { fetchAxios, formatHandledErrors } from "../../axiosConfig";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

interface AssignAdminValues {
  clientId?: string;
  adminId: string;
}

const INITIAL_VALUES_FORM: AssignAdminValues = {
  clientId: "",
  adminId: "",
};

const FormAssignAdminSchema = Yup.object().shape({
  clientId: Yup.string().required("Debes selecciona un tipo de cliente válido"),
  adminId: Yup.string().required("Debes selecciona un administrador válido"),
});

interface Props {
  closeModal: () => void;
  listAdmin: [];
  changeUpdateUserLicense: (id: any, data: any) => void;
}

export default function FormAssignAdmin({
  closeModal,
  listAdmin,
  changeUpdateUserLicense,
}: Props) {
  const { authState } = useAuth();
  const toast = useToast();

  const [clients, setClients] = useState([]);
  const [getClientsError, setGetClientsError] = useState<string | null>(null);

  const handleSendAssignAdmin = async (values: AssignAdminValues) => {
    try {
      const uri = `/user/assing_admin`;
      const axiosResponse = await fetchAxios(uri, {
        method: "POST",
        headers: { authorization: `Bearer ${authState.token}` },
        data: {
          idBartik: authState.user?.id,
          clientsID: Number(values.clientId),
          adminID: Number(values.adminId),
        },
      });

      const { message, type, newAssingAdmin } = axiosResponse.data.dataInfo;

      changeUpdateUserLicense(Number(values.adminId), newAssingAdmin);

      toast({
        title:
          type === "warning" ? "Ocurrio un problema" : "Licencias generadas",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      closeModal();
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      toast({
        title: "Error",
        description: error.response.data?.dataInfo?.message
          ? error.response.data.dataInfo.message
          : formatedError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getClients = async () => {
    try {
      const uri = `/marketing/get_clients_structure/${authState.user?.id}`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { listClients } = axiosResponse.data.dataInfo;

      setClients(listClients);
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      setGetClientsError(formatedError);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <Formik
      initialValues={INITIAL_VALUES_FORM}
      validationSchema={FormAssignAdminSchema}
      onSubmit={handleSendAssignAdmin}
    >
      {(props) => (
        <Form>
          <ModalBody>
            <Field name="clientId">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.clientId && form.touched.clientId}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="clientId">Cliente</FormLabel>
                  <AutoComplete
                    openOnFocus
                    rollNavigation
                    id="clientId"
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                  >
                    <AutoCompleteInput placeholder="Selecciona un cliente" />
                    <AutoCompleteList>
                      {clients.map((item: any) => (
                        <AutoCompleteItem
                          key={String(item.id)}
                          value={item}
                          label={`${item.nit} | ${item.businessName} | ${item.divisionName}`}
                        >
                          {item.nit} | {item.businessName} | {item.divisionName}
                        </AutoCompleteItem>
                      ))}
                    </AutoCompleteList>
                  </AutoComplete>

                  {getClientsError && (
                    <Alert status="error" mt="0.5em">
                      <AlertIcon />
                      {getClientsError}
                    </Alert>
                  )}

                  <FormErrorMessage>{form.errors.clientId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="adminId">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={form.errors.adminId && form.touched.adminId}
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="adminId">Administradores</FormLabel>
                  <AutoComplete
                    openOnFocus
                    rollNavigation
                    id="adminId"
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                  >
                    <AutoCompleteInput placeholder="Selecciona un administrador" />
                    <AutoCompleteList>
                      {listAdmin.map((item: any) => (
                        <AutoCompleteItem
                          key={String(item.idUsers)}
                          value={item}
                          label={`${item.name} | ${item.numberID}`}
                        >
                          {item.name} | {item.numberID}
                        </AutoCompleteItem>
                      ))}
                    </AutoCompleteList>
                  </AutoComplete>

                  {getClientsError && (
                    <Alert status="error" mt="0.5em">
                      <AlertIcon />
                      {getClientsError}
                    </Alert>
                  )}

                  <FormErrorMessage>{form.errors.adminId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={closeModal}
              isDisabled={props.isSubmitting}
              mr="0.5em"
            >
              Cerrar
            </Button>

            <Button
              type="submit"
              colorScheme="darkBlue"
              isLoading={props.isSubmitting}
              isDisabled={props.isSubmitting}
            >
              Guardar
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}
