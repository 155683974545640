import React, { useState } from "react";
import FocusLock from "react-focus-lock";
import dayjs from "dayjs";
import es from "dayjs/locale/es";
import {
  Box,
  Button,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion,
  Text,
  Heading,
  Image,
  Badge,
  Tag,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  FormControl,
  FormLabel,
  ButtonGroup,
  Input,
  Stack,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
  useToast,
  Icon,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  PopoverTrigger,
} from "@chakra-ui/react";
import { FiMail, FiEdit } from "react-icons/fi";
import NoImage from "../../assets/images/no-avatar.png";
import { fetchAxios, formatHandledErrors } from "../../axiosConfig";
import { useAuth } from "../../hooks/useAuth";
import { BiBlock, BiCheck, BiLockOpenAlt, BiX } from "react-icons/bi";

interface Props {
  key: any;
  id: number;
  name: string;
  nit: string;
  image?: string;
  email: string;
  status: number;
  licenses: License[] | [];
  updateEmailClient: (id: number, newEmail: string) => void;
  updateStatus: (numberId: string, newStatus: number) => void;
}

interface License {
  idlicenses: number;
  keyLicense: string;
  appName: string;
  statusKey: string;
  version: string;
  expirationDate: string;
}

const TextInputEmail = React.forwardRef((props: any, ref) => {
  return (
    <FormControl>
      <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
      <Input ref={ref} id={props.id} {...props} />
    </FormControl>
  );
});

const FormChangeEmail = ({
  firstFieldRef,
  onCancel,
  defaultEmail,
  clientId,
  updateEmailClient,
}: any) => {
  const toast = useToast();
  const { authState } = useAuth();

  const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);

  const handleUpdateEmail = async () => {
    const emailReference = firstFieldRef.current;

    if (!emailReference) return;

    setIsUpdatingEmail(true);

    try {
      const uri = `/client/update_client_email/${clientId}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: { newEmail: emailReference.value },
      });

      const { message } = axiosResponse.data.dataInfo;

      toast({
        title: "Email actualizado",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      updateEmailClient(clientId, emailReference.value);
      setIsUpdatingEmail(false);
      onCancel();
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);

      toast({
        title: "Error",
        description: formatedError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsUpdatingEmail(false);
    }
  };

  return (
    <Stack spacing={4}>
      <TextInputEmail
        label="Email"
        id="email"
        ref={firstFieldRef}
        defaultValue={defaultEmail}
      />
      <ButtonGroup display="flex" justifyContent="flex-end">
        <Button
          variant="outline"
          isDisabled={isUpdatingEmail}
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleUpdateEmail}
          isDisabled={isUpdatingEmail}
          colorScheme="greenDark"
        >
          Guardar
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

const ClientCardAdmin = ({
  id,
  name,
  nit,
  image,
  email,
  status,
  licenses,
  updateEmailClient,
  updateStatus,
}: Props) => {
  const bgAccordionExpanded = useColorModeValue("darkBlue.800", "darkBlue.300");
  const colorAccordionExpanded = useColorModeValue("white", "gray.700");
  const emailColor = useColorModeValue("gray.500", "gray.300");
  const emailColorExpanded = useColorModeValue("whiteSmoke", "gray.600");
  const borderColor = useColorModeValue("gray.100", "gray.600");

  const borderTopColor = useColorModeValue("gray.100", "gray.600");

  const {
    isOpen: isOpenModalStatus,
    onOpen: onOpenModalStatus,
    onClose: onCloseModalStatus,
  } = useDisclosure();

  const firstFieldRef = React.useRef(null);
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);

  const { authState } = useAuth();

  const img = `${process.env.REACT_APP_IMAGES_API_URL}/image/get-image-profile/${image}`;

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const handleSendWelcomeEmail = async () => {
    setIsSendingEmail(true);

    try {
      const uri = `/client/send_welcome_email/${id}`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { message } = axiosResponse.data.dataInfo;

      toast({
        title: "Email enviado",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSendingEmail(false);
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);

      toast({
        title: "Error",
        description: formatedError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsSendingEmail(false);
    }
  };

  const handleChangeUserStatus = async () => {
    setIsLoadingStatus(true);

    try {
      const newStatus = status === 1 ? 0 : 1;

      const uri = `/client/change_clients_status/${nit}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: { newStatus },
      });

      const { message } = axiosResponse.data.dataInfo;

      toast({
        title: "Usuario actualizado",
        description: message,
        status: "success",
        isClosable: true,
        duration: 5000,
        position: "top-right",
      });

      updateStatus(nit, newStatus);

      setIsLoadingStatus(false);
      onCloseModalStatus();
    } catch (error: any) {
      setIsLoadingStatus(false);
      const formatedMessage = formatHandledErrors(error);

      toast({
        title: "Error",
        description: formatedMessage,
        duration: 5000,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <Accordion
        allowToggle
        width="full"
        mb="1em"
        key={id}
        sx={{
          ":last-child": {
            mb: "0",
          },
        }}
      >
        <AccordionItem
          borderColor={borderColor}
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="5px"
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton
                py="0.8em"
                borderTopRadius="5px"
                _expanded={{
                  bg: bgAccordionExpanded,
                  color: colorAccordionExpanded,
                }}
              >
                <Box display="flex" alignItems="center" width="full">
                  <Image
                    src={image ? img : NoImage}
                    alt={`Seguridad Penta LTDA Logo`}
                    mr="1em"
                    width="60px"
                    borderRadius="5px"
                  />
                  <Box textAlign="left" pl="1em">
                    <Heading size="md">{name}</Heading>
                    <Text>
                      <b>NIT:</b> {nit}
                    </Text>
                    <Text
                      fontSize="0.9em"
                      overflow="hidden"
                      wordBreak="break-word"
                      color={isExpanded ? emailColorExpanded : emailColor}
                    >
                      {email}
                    </Text>
                  </Box>
                  <AccordionIcon fontSize="1.8em" ml="auto" mr="0.5em" />
                </Box>
              </AccordionButton>
              <AccordionPanel p="1em">
                <Box>
                  <Button
                    variant="outline"
                    size="sm"
                    mr="0.5em"
                    leftIcon={<FiMail />}
                    loadingText="Enviando"
                    isLoading={isSendingEmail}
                    onClick={handleSendWelcomeEmail}
                  >
                    Reenviar correo de bienvenida
                  </Button>
                  <Popover
                    isOpen={isOpen}
                    initialFocusRef={firstFieldRef}
                    onOpen={onOpen}
                    onClose={onClose}
                    closeOnBlur={false}
                  >
                    <PopoverTrigger>
                      <Button
                        variant="outline"
                        size="sm"
                        mr="0.5em"
                        leftIcon={<FiEdit />}
                      >
                        Editar Email
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent p={5}>
                      <FocusLock returnFocus persistentFocus={false}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <FormChangeEmail
                          firstFieldRef={firstFieldRef}
                          onCancel={onClose}
                          defaultEmail={email}
                          clientId={id}
                          updateEmailClient={updateEmailClient}
                        />
                      </FocusLock>
                    </PopoverContent>
                  </Popover>

                  <Button
                    variant="outline"
                    size="sm"
                    mr="0.5em"
                    colorScheme={status === 1 ? "red" : "green"}
                    leftIcon={
                      <Icon as={status === 1 ? BiBlock : BiLockOpenAlt} />
                    }
                    loadingText="Enviando"
                    isLoading={isSendingEmail}
                    onClick={onOpenModalStatus}
                  >
                    {status === 1
                      ? "  Bloquear cliente"
                      : "Desbloquear cliente"}
                  </Button>
                </Box>

                {/* TODO: agregar opcion de editar email */}

                <Box mt="1em">
                  <Box
                    borderWidth="thin"
                    borderStyle="solid"
                    padding="0.5em"
                    borderColor={borderTopColor}
                    borderRadius="10px"
                    sx={{
                      "@media (max-width: 600px)": {
                        overflowX: "scroll",
                      },
                    }}
                  >
                    <Table variant="simple" size="md">
                      <Thead>
                        <Tr>
                          <Th>Clave de licencia</Th>
                          <Th>Aplicacion</Th>
                          <Th>Version</Th>
                          <Th>Estado</Th>
                          <Th isNumeric>Fecha de expiración</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {licenses?.map((license: License, index: any) => (
                          <Tr key={index}>
                            <Td>{license.keyLicense}</Td>
                            <Td>{license.appName}</Td>
                            <Td>
                              <Tag size="sm" variant="outline">
                                {license.version}
                              </Tag>
                            </Td>
                            <Td>
                              {license.statusKey === "EXPIRED" ? (
                                <Badge colorScheme="orange">EXPIRADA</Badge>
                              ) : license.statusKey === "IN USE" ? (
                                <Badge colorScheme="darkBlue">EN USO</Badge>
                              ) : (
                                <Badge>DISPONIBLE</Badge>
                              )}
                            </Td>
                            <Td isNumeric>
                              {!license.expirationDate
                                ? "NO REGISTRADO"
                                : dayjs(license.expirationDate)
                                    .locale(es)
                                    .format("DD/MM/YYYY")}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>Clave de licencia</Th>
                          <Th>Aplicacion</Th>
                          <Th>Version</Th>
                          <Th>Estado</Th>
                          <Th isNumeric>Fecha de expiración</Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </Box>
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>

      <Modal
        isCentered
        onClose={onCloseModalStatus}
        isOpen={isOpenModalStatus}
        motionPreset="slideInBottom"
        closeOnEsc={!isLoadingStatus}
        closeOnOverlayClick={!isLoadingStatus}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            {status === 1 ? "Bloquear" : "Activar"} Usuario
            <Box ml="0.3em" mt="0.2em">
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg={status === 1 ? "red.500" : "green.500"}
                rounded={"50px"}
                w={"15px"}
                h={"15px"}
                textAlign="center"
              >
                <Icon
                  as={status === 1 ? BiX : BiCheck}
                  fontSize="15px"
                  color={"white"}
                />
              </Flex>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text display="inline-block">
              ¿Estas seguro de {status === 1 ? "Bloquear" : "Activar"} al
              usuario:{" "}
              <Text as="span" fontWeight="500">
                {name}
              </Text>
              ?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={onCloseModalStatus}
              isLoading={isLoadingStatus}
              isDisabled={isLoadingStatus}
            >
              Cerrar
            </Button>
            <Button
              colorScheme="darkBlue"
              isLoading={isLoadingStatus}
              isDisabled={isLoadingStatus}
              onClick={handleChangeUserStatus}
            >
              {status === 1 ? "Bloquear" : "Activar"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ClientCardAdmin;
