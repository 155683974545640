import { AuthActions } from '../actions/authActions';
import { AuthState } from '../interfaces/authInterface';

const authReducer = (state: AuthState, action: AuthActions): AuthState => {

  switch (action.type) {
    case '[AUTH]: Login':
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
        token: action.payload.token,
        isTemporalPass: action.payload.isTemporalPass
      };

    case '[AUTH]: Logout':
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        token: '',
        isTemporalPass: false
      };

    case '[AUTH]: ImageUpload':
      return {
        ...state,
        user: {
          ...state.user,
          image: action.payload
        }
      };

    default:
      return state;
  }

};

export default authReducer;