import { createContext, useReducer } from 'react';

import { AuthActions } from '../actions/authActions';
import { AuthState } from '../interfaces/authInterface';

import authReducer from '../reducers/authReducer';

interface AuthContextProps {
  authState: AuthState;
  dispatch: React.Dispatch<AuthActions>
};

interface Props {
  children: JSX.Element | JSX.Element[];
};

const initialState: AuthState = {
  user: null,
  token: '',
  isTemporalPass: false,
  isLoggedIn: false
};

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: Props): JSX.Element => {

  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ authState: state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );

};