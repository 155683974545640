import { useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  Box,
  Heading,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
  Button,
  Divider,
  Link,
  useColorMode,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";

import * as Yup from "yup";

import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import useIsMounted from "../hooks/useIsMounted";

const RecoverySchema = Yup.object().shape({
  numberId: Yup.string()
    .min(4, "Demasiado corto!")
    .required("El número de identificación es un número requerido"),
});

interface RecoveryPasswordValues {
  numberId: string;
}

const INITIAL_VALUES: RecoveryPasswordValues = { numberId: "" };

const RecoveryPassword = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const { colorMode } = useColorMode();

  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const handleClearError = () => setErrorMessage(null);

  const handleSubmitRecoveryPassword = async (
    values: RecoveryPasswordValues
  ) => {
    try {
      const { numberId } = values;

      await fetchAxios("/auth/recovery_password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: { numberId },
      });

      navigate(`/recovery_sended/${numberId}`);
    } catch (error: any) {
      setErrorMessage(formatHandledErrors(error));

      if (isMounted()) {
        setTimeout(() => {
          handleClearError();
        }, 2000);
      }
    }
  };

  return (
    <Box mt="2em">
      <Heading mb="0.5em">Recuperación de contraseña</Heading>

      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={RecoverySchema}
        onSubmit={handleSubmitRecoveryPassword}
      >
        {(props) => (
          <Form>
            <Field name="numberId">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.5em"
                  isInvalid={form.errors.numberId && form.touched.numberId}
                  isDisabled={props.isSubmitting}
                  isRequired
                >
                  <FormLabel htmlFor="numberId">
                    Número de identificación
                  </FormLabel>
                  <Input
                    {...field}
                    id="numberId"
                    placeholder="Número de identificación"
                    spellCheck="false"
                    autoComplete="off"
                  />
                  <FormHelperText>
                    Tu nueva contraseña será enviada al email registrado
                  </FormHelperText>
                  <FormErrorMessage>{form.errors.numberId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {errorMessage && (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>{errorMessage}</AlertDescription>
                <CloseButton
                  onClick={handleClearError}
                  position="absolute"
                  right="8px"
                  top="8px"
                />
              </Alert>
            )}

            <Button
              mt={3}
              colorScheme="greenDark"
              isLoading={props.isSubmitting}
              loadingText="Enviando"
              type="submit"
            >
              Recuperar Contraseña
            </Button>
          </Form>
        )}
      </Formik>

      <Divider my="1.5em" />

      <RouterLink to="/login">
        <Link
          color={colorMode === "dark" ? "white" : "darkBlue"}
          as="span"
          textAlign="center"
          width="100%"
          display="block"
        >
          ¿Regresar al inicio? Iniciar Sesión
        </Link>
      </RouterLink>
    </Box>
  );
};

export default RecoveryPassword;
