import React, { ReactNode, ReactText, useEffect, useState } from "react";
import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
  useNavigate,
} from "react-router-dom";

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorMode,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Image,
  Badge,
  ScaleFade,
  PopoverContent,
  Portal,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Popover,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  Spinner,
  PopoverTrigger,
} from "@chakra-ui/react";

import {
  FiHome,
  FiCreditCard,
  FiUsers,
  FiSettings,
  FiMenu,
  FiLogOut,
  FiSun,
  FiMoon,
  FiAlertCircle,
  FiGlobe,
  FiBell,
} from "react-icons/fi";

import { IconType } from "react-icons";
import LogoClaro from "../assets/images/logo-claro.svg";
import LogoOscuro from "../assets/images/logo-oscuro.svg";
import { useAuth } from "../hooks/useAuth";
import { doLogout } from "../actions/authActions";
import NoAvatar from "../assets/images/no-avatar.png";
import NotificationCard from "../components/NotificationCard";
import { useNotifications } from "../hooks/useNotifications";
import FormUpdateUser from "../components/users/FormUpdateUser";
import { fetchAxios, formatHandledErrors } from "../axiosConfig";

interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
}

const LinkItems: Array<LinkItemProps> = [
  { name: "Inicio", icon: FiHome, path: "/app/home" },
  { name: "Clientes y Licencias", icon: FiCreditCard, path: "/app/licenses" },
  { name: "Sub-Clientes", icon: FiUsers, path: "/app/client" },
  { name: "Usuarios", icon: FiUsers, path: "/app/user" },
  { name: "Informes", icon: FiSettings, path: "/app/report" },
  { name: "Solicitudes", icon: FiAlertCircle, path: "/app/request" },
  { name: "Marketing", icon: FiGlobe, path: "/app/marketing" },
];

const LinkItemsUser: Array<LinkItemProps> = [
  { name: "Inicio", icon: FiHome, path: "/app/home" },
  { name: "Licencias", icon: FiCreditCard, path: "/app/licenses" },
  { name: "Clientes", icon: FiUsers, path: "/app/client" },
  { name: "Usuarios", icon: FiUsers, path: "/app/user" },
  { name: "Informes", icon: FiSettings, path: "/app/report" },
  { name: "Solicitudes", icon: FiAlertCircle, path: "/app/request" },
];

const LinkItemsUserIndividual: Array<LinkItemProps> = [
  { name: "Inicio", icon: FiHome, path: "/app/home" },
  { name: "Licencias", icon: FiCreditCard, path: "/app/licenses" },
  { name: "Usuarios", icon: FiUsers, path: "/app/user" },
  { name: "Informes", icon: FiSettings, path: "/app/report" },
  { name: "Solicitudes", icon: FiAlertCircle, path: "/app/request" },
];

const Sidebar = ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgBoxOutlet = useColorModeValue("gray.50", "whiteAlpha.50");

  return (
    <Box minH="100vh" bg={bgBoxOutlet}>
      <SidebarContent
        onClose={onClose}
        display={{ base: "none", md: "block" }}
        /* bg={bgBoxOutlet} */
      />

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { authState } = useAuth();

  const { colorMode } = useColorMode();

  const srcImage = colorMode === "dark" ? LogoOscuro : LogoClaro;

  return (
    <Box
      transition="width 3s ease"
      bg={useColorModeValue("white", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      paddingY="15px"
      borderRight="1px"
      borderRightColor={useColorModeValue("whiteSmoke", "gray.600")}
      {...rest}
      zIndex={1}
      onClick={onClose}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Image
          src={srcImage}
          alt="Visitik Panel Web Logo"
          width="75px"
          height="75px"
        />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>

      {authState.user?.clientType === "Bartik"
        ? LinkItems.map((link) => (
            <NavItem key={link.name} path={link.path} icon={link.icon}>
              {link.name}
            </NavItem>
          ))
        : authState.user?.clientType === "Empresarial"
        ? LinkItemsUser.map((link) => (
            <NavItem key={link.name} path={link.path} icon={link.icon}>
              {link.name}
            </NavItem>
          ))
        : LinkItemsUserIndividual.map((link) => (
            <NavItem key={link.name} path={link.path} icon={link.icon}>
              {link.name}
            </NavItem>
          ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  path: String;
  children: ReactText;
}

const NavItem = ({ icon, path, children, ...rest }: NavItemProps) => {
  const resolved = useResolvedPath(`${path}`);
  const match = useMatch({ path: resolved.pathname, end: true });

  const linkColor = useColorModeValue(
    match ? "greenDark.500" : "gray.800",
    match ? "greenDark.500" : "white"
  );

  return (
    <RouterLink to={`${path}`}>
      <Flex
        align="center"
        p="4"
        mx="4"
        color={linkColor}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{ bg: "greenDark.500", color: "white" }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{ color: "white" }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </RouterLink>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { dispatch, authState } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();

  const img = `${process.env.REACT_APP_IMAGES_API_URL}/image/get-image-profile/${authState.user?.image}`;

  const navigate = useNavigate();

  const { loading, notifications, total, Notifications, updateCount } =
    useNotifications();

  const iconChangeTheme = colorMode === "dark" ? <FiSun /> : <FiMoon />;

  const [open, setopen] = useState(false);
  const [users, setusers] = useState<any>({});
  const { isOpen, onClose } = useDisclosure();
  const [getUsersErrorMsg, setGetUsersErrorMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleModalOpen = () => {
    setopen(true);
  };

  const handleModalClose = () => {
    setopen(false);
  };

  const handleGetUsers = async () => {
    try {
      const uri = `/user/get-users-perfil/${authState.user?.id}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "GET",
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { dataInfo } = axiosResponse.data;

      setusers(dataInfo);
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      setGetUsersErrorMsg(formatedError);
    }
  };

  const changeError = (error: any) => {
    setGetUsersErrorMsg(error);
  };

  useEffect(() => {
    setIsLoading(true);
    Notifications();
    handleGetUsers();
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  const handleLogout = () => {
    window.localStorage.removeItem("client_token");
    dispatch(doLogout());

    navigate("/login");
  };

  return (
    <>
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("white", "gray.700")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Flex
          onClick={handleModalOpen}
          alignItems={"center"}
          marginRight="auto"
          marginLeft={{ base: "10px", md: "0" }}
        >
          <HStack spacing="3">
            {isLoading ? (
              <Spinner size="sm" speed="0.25s" />
            ) : (
              <Avatar
                size="sm"
                src={authState.user?.image ? img : NoAvatar}
                ignoreFallback
              />
            )}
            <VStack
              display={{ base: "none", md: "flex" }}
              alignItems="flex-start"
              spacing="1px"
              ml="2"
            >
              <Text fontSize="sm">{authState.user?.businessName}</Text>
            </VStack>
          </HStack>
        </Flex>

        <HStack spacing={{ base: "1", md: "2" }}>
          <Popover>
            <PopoverTrigger>
              <IconButton
                variant="ghost"
                size="lg"
                icon={<FiBell />}
                aria-label={"open menu"}
              />
            </PopoverTrigger>

            {total > 0 && (
              <Badge
                position="absolute"
                color="white"
                top="10px"
                right="120px"
                fontSize="0.9em"
                bg="red"
                borderRadius="full"
                boxSize="20px"
                textAlign="center"
                px={"auto"}
              >
                {total}
              </Badge>
            )}

            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>Tus Notificaciones</PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                  <NotificationCard
                    onToggle={toggleColorMode}
                    notifications={notifications}
                    isLoading={loading}
                    onClose={onClose}
                    updateCount={updateCount}
                  />
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>

          <IconButton
            size="lg"
            variant="ghost"
            aria-label="Change Theme"
            icon={iconChangeTheme}
            onClick={toggleColorMode}
            marginRight={{ base: "10px", md: "0" }}
          />
          <IconButton
            size="lg"
            variant="ghost"
            aria-label="Cerrar Sesión"
            icon={<FiLogOut />}
            onClick={handleLogout}
            bg="bgBoxOutlet"
            marginRight={{ base: "10px", md: "0" }}
          />
        </HStack>
      </Flex>

      <Box
        position="absolute"
        display="flex"
        justifyContent="end"
        width="full"
        onChange={toggleColorMode}
        borderRightColor={useColorModeValue("whiteSmoke", "black.600")}
        borderRight="1000px"
      >
        <ScaleFade
          initialScale={0.9}
          in={isOpen}
          reverse={true}
          unmountOnExit={true}
        >
          <Box /* background="whiteSmoke" */
            roundedTop="md"
            width="md"
            onChange={toggleColorMode}
            textAlign="center"
          >
            <Text>
              En este apartado solo se muestran algunas notificaciones.
            </Text>
          </Box>
          <Box
            overflow="auto"
            p="10px"
            color="secondaryTextColor"
            // onToggle={toggleColorMode}
            roundedBottom="md"
            width="md"
            height="md"
          >
            <NotificationCard
              onToggle={toggleColorMode}
              notifications={notifications}
              isLoading={loading}
              onClose={onClose}
              updateCount={updateCount}
            />
          </Box>
        </ScaleFade>
      </Box>

      <Modal isOpen={open} onClose={handleModalClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
            Perfil
          </ModalHeader>
          <ModalCloseButton />
          <FormUpdateUser
            users={users}
            getUsersErrorMsg={getUsersErrorMsg}
            handleModalClose={handleModalClose}
            changeError={changeError}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default Sidebar;
