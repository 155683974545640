import { useEffect, useState, useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Container, useDisclosure, useToast } from "@chakra-ui/react";

import SidebarWithHeader from "./Sidebar";
import LoadingScreen from "../components/LoadingScreen";
import ChangePasswordModal from "../components/ChangePasswordModal";

import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import { useAuth } from "../hooks/useAuth";
import { doLogin, doLogout } from "../actions/authActions";

const DashboardLayout = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { dispatch, authState } = useAuth();

  const [isChecking, setIsChecking] = useState(true);

  const handleChangePassword = useCallback(
    async (values: any) => {
      try {
        const token = authState.token;

        await fetchAxios("/user/change_password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
          data: {
            numberId: authState.user?.nit,
            password: values.password,
            confirm: values.confirm,
          },
        });

        dispatch(
          doLogin({
            ...authState,
            isTemporalPass: false,
          })
        );

        toast({
          title: "Contraseña actualizada",
          description: "La contraseña se cambio de manera correcta",
          status: "success",
          duration: 6000,
          isClosable: true,
          position: "top-right",
        });

        onClose();
      } catch (error: any) {
        const errorMsg = formatHandledErrors(error);

        toast({
          title: "Error",
          description: errorMsg,
          status: "error",
          duration: 6000,
          isClosable: true,
          position: "top-right",
        });
      }
    },
    [authState]
  );

  const refreshUserToken = useCallback(async () => {
    try {
      const clientToken = window.localStorage.getItem("client_token");

      const axiosResponse = await fetchAxios("/auth/refresh_token", {
        method: "POST",
        data: { oldToken: clientToken },
      });

      const { newToken, userData, isTemporalPass } =
        axiosResponse.data.dataInfo;

      dispatch(
        doLogin({
          isLoggedIn: true,
          user: userData,
          token: newToken,
          isTemporalPass,
        })
      );

      window.localStorage.setItem("client_token", newToken);

      setIsChecking(false);

      if (isTemporalPass) onOpen();
    } catch (error) {
      setIsChecking(false);
      dispatch(doLogout());
      window.localStorage.removeItem("client_token");
      navigate("/login", {
        state: { msg: "La sesión se usuario ha expirado" },
      });
    }
  }, []);

  useEffect(() => {
    refreshUserToken();
  }, []);

  if (isChecking) return <LoadingScreen />;

  return (
    <Container maxW="full" minH="100vh" padding="0">
      <SidebarWithHeader>
        <Outlet />
      </SidebarWithHeader>

      <ChangePasswordModal
        isOpen={isOpen}
        onClose={onClose}
        bodyText="Por favor actualiza tu contraseña antes de continuar"
        hasCloseButton={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        handleSendForm={handleChangePassword}
      />
    </Container>
  );
};

export default DashboardLayout;
