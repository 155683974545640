import { Box, Text, BoxProps, IconButton, Button } from "@chakra-ui/react";

import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";

interface Props extends BoxProps {
  total: number;
  page: number;
  itemsPerPage: number;
  handleChangePage: (pageNumber: number) => void;
}

const Pagination = ({
  total,
  page,
  itemsPerPage,
  handleChangePage,
  ...rest
}: Props) => {
  const totalPages =
    Math.ceil(total / itemsPerPage) < 1 ? 1 : Math.ceil(total / itemsPerPage);

  const formatedPage = page < 1 ? 1 : page + 1;

  const offsetNumber = formatedPage * itemsPerPage - itemsPerPage + 1;

  const offsetNumberEnd =
    page === totalPages ? total : formatedPage * itemsPerPage;

  return (
    <Box display="flex" alignItems="center" {...rest}>
      <Text  fontSize="0.95em">
        Mostrando {offsetNumber}-{offsetNumberEnd} de {total}
      </Text>
      <Box display="flex" alignItems="center">
        <IconButton
          aria-label="Primera Pagina"
          //mr="0.5em"
          variant="ghost"
          icon={<FiChevronsLeft />}
          disabled={page <= 0}
          onClick={() => handleChangePage(0)}
        />

        <IconButton
          aria-label="Anterior"
          //mr="0.5em"
          variant="ghost"
          icon={<FiChevronLeft />}
          disabled={page <= 0}
          onClick={() => handleChangePage(page - 1)}
        />

        <Button variant="ghost">{page < 0 ? 1 : page + 1}</Button>

        <IconButton
          aria-label="Siguiente"
          variant="ghost"
          //ml="0.5em"
          icon={<FiChevronRight />}
          disabled={formatedPage === totalPages}
          onClick={() => handleChangePage(page + 1)}
        />

        <IconButton
          aria-label="Ultima Pagina"
          variant="ghost"
          //ml="0.5em"
          icon={<FiChevronsRight />}
          disabled={formatedPage === totalPages}
          onClick={() => handleChangePage(totalPages - 1)}
        />
      </Box>
    </Box>
  );
};

export default Pagination;
