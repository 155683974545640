import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";

import {
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Alert,
  AlertIcon,
  Button,
  Text,
  Icon,
} from "@chakra-ui/react";

import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

import { fetchAxios, formatHandledErrors } from "../../axiosConfig";
import { useAuth } from "../../hooks/useAuth";
import { FiInfo } from "react-icons/fi";

interface Props {
  handleSubmitFor: (values: FormNewLicenseValues) => void;
  onClose: () => void;
}

export interface FormNewLicenseValues {
  idsubClient: string;
  idlicense: number;
}

const FORM_NEW_LICENSE_INITIAL_VALUES: FormNewLicenseValues = {
  idsubClient: "",
  idlicense: 0,
};

const FormNewLicenseSchema = Yup.object().shape({
  idsubClient: Yup.string().notOneOf(
    ["", null],
    "Debes selecciona un tipo de cliente válido"
  ),
  idlicense: Yup.string().notOneOf(
    ["none", null],
    "Debes seleccionar una licencia valida"
  ),
});

const ModalFormAssignLicense = ({ handleSubmitFor, onClose }: Props) => {
  const { authState } = useAuth();

  const [clients, setClients] = useState([]);
  const [getClientsError, setGetClientsError] = useState<string | null>(null);
  const [licenses, setLicenses] = useState([]);

  const getClients = async () => {
    try {
      const uri = `/client/get_clients/${authState.user?.id}?rol=user`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { rows } = axiosResponse.data.dataInfo;

      setClients(rows);
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);
      setGetClientsError(formatedError);
    }
  };

  const getLicenses = async (idClient: number) => {
    try {
      const uri = `/client/get_licenses/${idClient}?role=user&type=new`;
      const axiosResponse = await fetchAxios(uri, {
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { rows } = axiosResponse.data.dataInfo;

      setLicenses(rows);
    } catch (error) {
      setLicenses([]);
    }
  };

  useEffect(() => {
    if (authState.user?.clientType === "Bartik") {
      getClients();
    } else {
      getLicenses(authState.user?.id || 0);
      getClients();
    }
  }, [authState]);

  return (
    <Formik
      initialValues={FORM_NEW_LICENSE_INITIAL_VALUES}
      onSubmit={handleSubmitFor}
      validationSchema={FormNewLicenseSchema}
    >
      {(props) => (
        <Form>
          <ModalBody>
            <Field name="idsubClient">
              {({ field, form }: any) => (
                <FormControl
                  mb="0.8em"
                  isInvalid={
                    form.errors.idsubClient && form.touched.idsubClient
                  }
                  isDisabled={props.isSubmitting}
                >
                  <FormLabel htmlFor="idsubClient">Cliente</FormLabel>
                  <AutoComplete
                    openOnFocus
                    rollNavigation
                    id="idsubClient"
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                  >
                    <AutoCompleteInput placeholder="Selecciona un cliente" />
                    <AutoCompleteList>
                      {clients.map((item: any) => (
                        <AutoCompleteItem
                          key={String(item.id)}
                          value={String(item.id)}
                          label={`${item.nit} | ${item.businessName}`}
                        >
                          {item.nit} | {item.businessName}
                        </AutoCompleteItem>
                      ))}
                    </AutoCompleteList>
                  </AutoComplete>

                  {getClientsError && (
                    <Alert status="error" mt="0.5em">
                      <AlertIcon />
                      {getClientsError}
                    </Alert>
                  )}

                  <FormErrorMessage>{form.errors.clientId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="idlicense">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.idlicense && form.touched.idlicense}
                  mb="0.5em"
                >
                  <FormLabel htmlFor="idlicense">Licencia asociada</FormLabel>
                  <Select {...field} id="idlicense">
                    <option disabled value={0}>
                      Selecciona una licencia
                    </option>
                    {licenses.map((license: any) => (
                      <option
                        key={license.id}
                        value={license.id}
                        disabled={license.status === 0}
                      >
                        {license.label} -{" "}
                        {license.status === 0 && "(Bloqueado)"}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.licenseId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {licenses.length === 0 && (
              <Text as="span" mx="0.2em" color="yellow.400">
                <Icon
                  as={FiInfo}
                  color="yellow.400"
                  mr="0.5em"
                  display="inline"
                />
                No tiene licencias disponibles, porfavor solicite una nueva
                licencia.
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              variant="ghost"
              disabled={props.isSubmitting}
              onClick={onClose}
              mr="0.5em"
            >
              Cerrar
            </Button>
            <Button
              colorScheme="darkBlue"
              type="submit"
              disabled={props.isSubmitting || !!getClientsError}
            >
              Guardar
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default ModalFormAssignLicense;
