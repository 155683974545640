const subsubscribeNotification = async () => {
  try {
    await navigator.serviceWorker.register("./worker.js", {
      scope: "/",
    });
  } catch (error) {
    console.log(error);
  }
};

export default subsubscribeNotification;
