import axios, { AxiosError } from "axios";
export const fetchAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
export const fetchAxiosImg = axios.create({
  baseURL: process.env.REACT_APP_IMAGES_API_URL,
});
export const fetchMultimedia = axios.create({
  baseURL: process.env.REACT_APP_IMAGES_API_URL,
});

export const setRequestToken = (token: string): void => {
  const formatedToken = `Bearer ${token}`;
  fetchAxios.defaults.headers.common["authorization"] = formatedToken;
};

export const removeRequestToken = (): void => {
  fetchAxios.defaults.headers.common["Authorization"] = "";
};

interface ErrorResponse {
  status: number;
  errors: string; // Puedes ajustar este tipo según la estructura de tus errores
}

export const formatHandledErrors = (error: AxiosError): string => {
  let errMsg = "[Error] Ocurrió un error inesperado";

  if (error.response && error.response.data) {
    const responseData = error.response.data as ErrorResponse;
    errMsg = `[Error: ${responseData.status}]:  ${responseData.errors}`;
  } else if (error.request) {
    errMsg =
      "[Error: Indefinido]: Ocurrió un error realizando la petición, asegúrate de tener conexión a internet";
  } else {
    errMsg = `[Error: Cliente] ${error.message}`;
  }

  return errMsg;
};
