import {
  Box,
  Container,
  Stack,
  Text,
  VStack,
  Heading,
  SimpleGrid,
  useColorModeValue,
  List,
  ListItem,
  Spinner,
  Table,
  Thead,
  Tbody,
  Badge,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import { useAuth } from "../hooks/useAuth";
import dayjs from "dayjs";
import es from "dayjs/locale/es";

export default function RequestDetails() {
  const { authState } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [detailRequest, setDetailReques] = useState<any>([]);
  const toast = useToast();
  const [error, seterror] = useState<any>(null);

  const { id } = useParams();

  const handleGetDetail = async () => {
    setIsLoading(true);

    try {
      const uri = `/request/get-detail-request/${id}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "GET",
        headers: { authorization: `Bearer ${authState.token}` },
      });

      const { dataRequest } = axiosResponse.data.dataInfo;

      setDetailReques(dataRequest);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      const formatedError = formatHandledErrors(error);
      seterror(formatedError);
    }
  };

  const updateStatusArray = (newStatus: any) => {
    setDetailReques({
      ...detailRequest,
      status: newStatus,
    });
  };

  const handleUpdateStatus = async (newStatus: any) => {
    try {
      const uri = `/request/update-request-status/${id}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: { newStatus, idclients: authState.user?.id },
      });

      const { message, type, status } = axiosResponse.data.dataInfo;

      updateStatusArray(status);

      toast({
        title: type === "warning" ? "Ocurrio un problema" : "Exito",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      const formatedError = formatHandledErrors(error);
      seterror(formatedError);
    }
  };

  useEffect(() => {
    handleGetDetail();
  }, [id]);

  return (
    <Container
      maxW={"7xl"}
      width="full"
      bg={useColorModeValue("white", "gray.700")}
      p="5"
      wordBreak="break-all"
      borderRadius="10px"
      sx={{
        "@media (max-width: 600px)": {
          overflowX: "scroll",
        },
      }}
    >
      {error && <Text>{error}</Text>}

      {isLoading && (
        <>
          <Spinner mr="0.5em" /> Cargando
        </>
      )}
      <SimpleGrid
        width="full"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 18, md: 0 }}
      >
        <Stack spacing={{ base: 6, md: 10 }} width="full">
          <Box as={"header"}>
            <Heading
              lineHeight={1.1}
              fontWeight={400}
              fontSize={{ base: "2xl", sm: "2xl", lg: "3xl" }}
            >
              {detailRequest.titleRequest}
            </Heading>
          </Box>
          <Box mt="1em">
            <Box
              borderWidth="thin"
              borderStyle="solid"
              borderRadius="10px"
              sx={{
                "@media (max-width: 600px)": {
                  overflowX: "scroll",
                },
              }}
            >
              <TableContainer width={"full"} textAlign="initial">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th textAlign="center">Nombre Aplicación</Th>
                      <Th textAlign="center">Sistema operativo</Th>
                      <Th textAlign="center">Numero de activacion</Th>
                      <Th textAlign="center">Cantidad</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {detailRequest.requestText?.data.map((data: any) => (
                      <Tr key={data.appName}>
                        <Td textAlign="center">{data.appName}</Td>
                        <Td textAlign="center">{data.OS}</Td>
                        <Td textAlign="center">{data.num_activations}</Td>
                        <Td textAlign="center">{data.keysCount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={"column"}
            // divider={
            //   <StackDivider
            //     borderColor={useColorModeValue("gray.200", "gray.600")}
            //   />
            // }
          >
            <VStack spacing={{ base: 4, sm: 6 }}>
              <Text fontSize={"lg"}>{detailRequest.textNotification}</Text>
            </VStack>
            <Box>
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                color={useColorModeValue("yellow.500", "yellow.300")}
                fontWeight={"500"}
                textTransform={"uppercase"}
                mb={"4"}
              >
                Informacion del cliente
              </Text>

              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <List spacing={2}>
                  <ListItem>Nombre: </ListItem>
                  <ListItem>Nit/cedula</ListItem> <ListItem>Correo</ListItem>
                </List>
                <List spacing={2}>
                  <ListItem>{detailRequest.businessName}</ListItem>
                  <ListItem>{detailRequest.nit}</ListItem>
                  <ListItem>{detailRequest.email}</ListItem>
                </List>
              </SimpleGrid>
            </Box>

            {detailRequest.closeDate && (
              <Text>
                Fecha de cierre:
                {dayjs(detailRequest.closeDate).isValid()
                  ? dayjs(detailRequest.closeDate)
                      .locale(es)
                      .format("DD [de] MMMM [de] YYYY")
                  : "No Registrado"}
              </Text>
            )}
          </Stack>

          {detailRequest.status === "En proceso" &&
            authState.user?.clientType === "Bartik" && (
              <Stack>
                <Box>
                  <Button
                    colorScheme="green"
                    margin={"10px"}
                    onClick={() => handleUpdateStatus("Aprobada")}
                  >
                    Aprobar
                  </Button>

                  <Button
                    colorScheme="red"
                    onClick={() => handleUpdateStatus("Rechazada")}
                  >
                    Rechazar
                  </Button>
                </Box>
              </Stack>
            )}

          {detailRequest.status === "Aprobada" && (
            <Stack>
              <Badge colorScheme="green">
                <Text>{detailRequest.status}</Text>
              </Badge>
            </Stack>
          )}

          {detailRequest.status === "Rechazada" && (
            <Stack>
              <Badge colorScheme="red">
                <Text>{detailRequest.status}</Text>
              </Badge>
            </Stack>
          )}
        </Stack>
      </SimpleGrid>
    </Container>
  );
}
