import { createContext, useState } from "react";
import { fetchAxios, formatHandledErrors } from "../axiosConfig";
import { useAuth } from "../hooks/useAuth";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const notificationsContext = createContext({} as any);

export const NotificationsProvider = ({ children }: Props): JSX.Element => {
  const { authState } = useAuth();

  const [loading, setIsLoading] = useState(false);
  const [notifications, setnotifications] = useState<any>([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [total, setTotal] = useState(0);

  const Notifications = async () => {
    setIsLoading(true);

    try {
      const uri = `/notification/get-notification/${authState.user?.id}`;

      const axiosResponse = await fetchAxios(uri, {
        method: "GET",
        headers: { authorization: `Bearer ${authState.token}` },
      });

      if (axiosResponse.status === 204) {
        setnotifications([]);
        setTotal(0);
        setIsLoading(false);
        return;
      }
      const { notificationClient, count } = axiosResponse.data.dataInfo;

      setnotifications(notificationClient);
      setTotal(count);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      const formatedError = formatHandledErrors(error);
      setErrorMsg(formatedError);
    }
  };

  const updateCount = async (id: number) => {
    try {
      const uri = `/notification/update-notification-read/${id}`;
      const axiosResponse = await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: "",
      });

      if (axiosResponse.status === 201) {
        const res = total - 1;
        setTotal(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <notificationsContext.Provider
      value={{
        loading: loading,
        notifications: notifications,
        errorMsg: errorMsg,
        total: total,
        Notifications,
        updateCount,
      }}
    >
      {children}
    </notificationsContext.Provider>
  );
};
