import dayjs from "dayjs";
import es from "dayjs/locale/es";

import {
  Box,
  Text,
  IconButton,
  Icon,
  Editable,
  EditableInput,
  EditablePreview,
  ButtonGroup,
  Flex,
  Divider,
  Badge,
  useEditableControls,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  useToast,
} from "@chakra-ui/react";

import { FiEdit, FiCheck, FiX } from "react-icons/fi";
import { fetchAxios, formatHandledErrors } from "../../axiosConfig";
import { useAuth } from "../../hooks/useAuth";
import { MdCheckCircle } from "react-icons/md";

interface Props {
  id: number;
  appName: string;
  customName: string;
  expirationDate: string;
  keyLicense: string;
  operatingSystem: string;
  statusKey: string;
  version: string;
  pcid: [];
  phone: string;
}

interface Licenses {
  pcid: string;
}

const ClientCard = (props: Props) => {
  const {
    id,
    appName,
    customName,
    expirationDate,
    keyLicense,
    operatingSystem,
    statusKey,
    version,
    pcid,
    phone,
  } = props;

  const formatedOS =
    operatingSystem === "WIN"
      ? "Windows"
      : operatingSystem === "OSX"
      ? "OSX (Mac OS)"
      : "Linux / Otro";

  const formatedStatus =
    statusKey === "FREE"
      ? "NO USADA"
      : statusKey === "IN USE"
      ? "EN USO"
      : "EXPIRADA";

  const statusColorScheme =
    statusKey === "FREE"
      ? "gray"
      : statusKey === "IN USE"
      ? "darkBlue"
      : "orange";

  const { authState } = useAuth();
  const toast = useToast();

  const borderColorContainer = useColorModeValue("gray.100", "gray.500");
  const hoverBgContainer = useColorModeValue("whiteSmoke", "gray.600");
  const titleColor = useColorModeValue("gray.600", "gray.100");

  const handleSendNewNameLicense = async (newValue: string) => {
    try {
      const uri = `/client/change_license_name/${authState.user?.id}`;
      await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: { idLicense: id, newName: newValue },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendPhoneLicense = async (newValue: string) => {
    try {
      const uri = `/client/change_license_phone/${authState.user?.id}`;
      await fetchAxios(uri, {
        method: "PUT",
        headers: { authorization: `Bearer ${authState.token}` },
        data: { idLicense: id, phone: newValue },
      });
    } catch (error: any) {
      const formatedError = formatHandledErrors(error);

      toast({
        title: "Error",
        description: formatedError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm" ml="auto">
        <IconButton
          aria-label="Enviar"
          variant="ghost"
          icon={<Icon as={FiCheck} />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label="Cancelar"
          variant="ghost"
          icon={<Icon as={FiX} />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center" ml="auto">
        <IconButton
          aria-label="Editar"
          size="sm"
          variant="ghost"
          icon={<Icon as={FiEdit} />}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  };

  return (
    <Box
      width="49%"
      borderWidth="thin"
      borderStyle="solid"
      borderColor={borderColorContainer}
      borderRadius="5px"
      mb="1em"
      mr="1em"
      p="0.6em 0.8em"
      transition="background-color .2s ease"
      _hover={{ bg: hoverBgContainer }}
      sx={{
        ":last-child": { mr: 0 },
        ":nth-of-type(even)": { mr: 0 },
        "@media (max-width: 900px)": {
          width: "100%",
        },
      }}
    >
      <Box mb="0.1em" width="full">
        <Editable
          textAlign="left"
          fontSize="xl"
          color={titleColor}
          defaultValue={customName}
          isPreviewFocusable={false}
          display="flex"
          alignItems="center"
          onSubmit={handleSendNewNameLicense}
        >
          <EditablePreview fontWeight="600" />
          <EditableInput pl="0.1em" mr="0.5em" />
          <EditableControls />
        </Editable>
      </Box>

      <Box mb="0.1em" mt={2} width="full">
        <Editable
          textAlign="left"
          fontSize="xl"
          color={titleColor}
          defaultValue={phone}
          isPreviewFocusable={false}
          display="flex"
          alignItems="center"
          onSubmit={handleSendPhoneLicense}
        >
          <EditablePreview fontWeight="600" />
          <EditableInput pl="0.1em" mr="0.5em" />
          <EditableControls />
        </Editable>
      </Box>

      <Divider my="0.5em" />

      <Box position="relative">
        <Badge
          colorScheme={statusColorScheme}
          //position="absolute"
          right="80%"
          top="0"
        >
          {formatedStatus}
        </Badge>

        <Text>
          <Text as="span" fontWeight="500">
            Clave:
          </Text>{" "}
          {keyLicense}
        </Text>
        <Text>
          <Text as="span" fontWeight="500">
            Sistema Operativo:
          </Text>{" "}
          {formatedOS}
        </Text>
        <Text>
          <Text as="span" fontWeight="500">
            Aplicación:
          </Text>{" "}
          {appName} | Versión {version}
        </Text>
        <Text>
          <Text as="span" fontWeight="500">
            Fecha de Expiración:{" "}
          </Text>
          {dayjs(expirationDate).isValid()
            ? dayjs(expirationDate).locale(es).format("DD [de] MMMM [de] YYYY")
            : "No Registrado"}
        </Text>

        <Box
          mt="0.5em"
          fontSize="0.8em"
          color={useColorModeValue("gray.500", "gray.300")}
        >
          {pcid.length !== 0
            ? pcid.map((items: Licenses, index: any) => (
                <List fontWeight="500" key={index}>
                  <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text>IDPC : {items.pcid}</Text>
                  </ListItem>
                </List>
              ))
            : "IDPC : No registrado"}
        </Box>
      </Box>
    </Box>
  );
};

export default ClientCard;
